import { Error, Info, Warning } from "@mui/icons-material";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

export enum BannerList {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

interface BannerProps {
  title?: string;
  body: string;
  bannerType: BannerList;
}

const bannerTypeMapping = {
  [BannerList.ERROR]: {
    borderColor: (theme: Theme) => theme.palette.error.dark,
    backgroundColor: (theme: Theme) => theme.palette.error.light,
    icon: (theme: Theme) => (
      <Error
        sx={{
          color: theme.palette.error.dark,
          fontSize: "48px",
        }}
      />
    ),
  },
  [BannerList.WARNING]: {
    borderColor: (theme: Theme) => theme.palette.warning.main,
    backgroundColor: (theme: Theme) => theme.palette.warning.light,
    icon: (theme: Theme) => (
      <Warning
        sx={{
          color: theme.palette.warning.main,
          fontSize: "48px",
        }}
      />
    ),
  },
  [BannerList.INFO]: {
    borderColor: (theme: Theme) => theme.palette.primary.main,
    backgroundColor: (theme: Theme) => theme.palette.secondary.light,
    icon: (theme: Theme) => (
      <Info
        sx={{
          color: theme.palette.primary.main,
          fontSize: "48px",
        }}
      />
    ),
  },
};

const getBannerProperties = (bannerType: BannerList, theme: Theme) => {
  const { borderColor, backgroundColor, icon } = bannerTypeMapping[bannerType];
  return {
    borderColor: borderColor(theme),
    backgroundColor: backgroundColor(theme),
    icon: icon(theme),
  };
};

export default function Banner({ title, body, bannerType }: BannerProps) {
  const theme = useTheme();

  const { borderColor, backgroundColor, icon } = getBannerProperties(
    bannerType,
    theme,
  );
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        flexDirection: "column",
        borderLeft: `6px solid ${borderColor}`,
        borderRadius: "6px",
        backgroundColor: backgroundColor,
        padding: theme.spacing(2),
        rowGap: theme.spacing(2),
      }}
    >
      {title && (
        <Typography sx={{ color: "black", fontWeight: 600 }}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          columnGap: theme.spacing(2),
        }}
      >
        <Box>{icon}</Box>
        <Box
          sx={{
            whiteSpace: "pre-line",
          }}
        >
          {body}
        </Box>
      </Box>
    </Box>
  );
}
