import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/MultiPageFormBuilder.js";
import {
  freeTextSchema,
  valueAndLabelSchema,
  yesNoSchema,
} from "../schemas.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysRequired({
    fieldName: "wasHealthcareProvided",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.wasHealthcareProvided",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "medicalAttentionProvided",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.wasHealthcareProvided?.value === "yes",
    label: "informationForHealthcare.medicalAttentionProvided",
  })
  .withFieldAlwaysRequired({
    fieldName: "hasPersonBeenSearched",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.hasPersonBeenSearched",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "hasAdditionalRiskFactors",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "informationForHealthcare.hasAdditionalRiskFactors",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "describeRiskFactors",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.hasAdditionalRiskFactors?.value === "yes",
    label: "informationForHealthcare.describeRiskFactors",
  })
  .withPage(["wasHealthcareProvided", "medicalAttentionProvided"])
  .withPage(["hasPersonBeenSearched"])
  .withPage(["hasAdditionalRiskFactors", "describeRiskFactors"])
  .build();

export type InformationForHealthcareFormData =
  typeof formSpecification.formType;
