import React, { useMemo, useState } from "react";

import { Box } from "@mui/material";
import {
  AvailableOrganisationArray,
  DEFAULT_WORKFLOW_VERSION,
  ExtendedVisionUser,
  FormSection,
  getSchemaWithOutAvailableOrganisations,
  IncidentWorkItemIdSchema,
  workflows,
} from "@vision/common";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { v4 } from "uuid";
import api from "../../api/index.js";
import { CreateIncidentWorkItemForm } from "../../forms/CreateIncidentWorkItem/CreateIncidentWorkItemForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { RootLoaderData } from "../../routing/loaders/root.js";

export type CreateIncidentWorkItemProps = {
  errorHandling: "throw" | "inline";
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
};

export type CreateIncidentWorkItemPageProps = {
  errorHandling: "throw" | "inline";
};

export function CreateIncidentWorkItem({
  errorHandling,
  user,
  availableOrganisations,
}: CreateIncidentWorkItemProps) {
  const navigate = useNavigate();

  const id = useMemo(() => IncidentWorkItemIdSchema.parse(v4()), []);
  const [error, setError] = useState<string | undefined>(undefined);

  if (error !== undefined && errorHandling === "throw") {
    throw new Error(error);
  }

  const workflowVersion =
    user.sessionOrganisationConfiguration?.workflowVersion ??
    DEFAULT_WORKFLOW_VERSION;

  const strictFieldSchemas =
    workflows[workflowVersion].createIncident.formDefinition.sections[0]
      .strictFieldSchemas;

  return (
    <>
      <CreateIncidentWorkItemForm
        onSubmit={async (values) => {
          try {
            // remove availableOrganisations from values and from the schema as don't want to send it to the server
            const {
              availableOrganisations,
              ...valuesWithoutAvailableOrganisations
            } = values;
            const modifiedSchema =
              getSchemaWithOutAvailableOrganisations(strictFieldSchemas);
            modifiedSchema.parse(valuesWithoutAvailableOrganisations);

            const response = await api.incidentWorkItem.create(
              id,
              // make sure sending the correct fields for specific workflow
              valuesWithoutAvailableOrganisations,
            );

            if (response.status === 204) {
              navigate(`/incident/${id}/section-complete`, {
                state: {
                  type: FormSection.createIncidentMain,
                  payload: {
                    officer: user.name,
                  },
                },
              });
              return;
            } else {
              throw new Error(JSON.stringify(response.data.message));
            }
          } catch (error) {
            setError(
              "Failed to create incident work item: " +
                JSON.stringify((error as Error).message),
            );
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        defaultCommandUnitOrganisationId={user.sessionContext?.organisationId!}
        onCancel={() => navigate(createRoute.home())}
        availableOrganisations={availableOrganisations}
        workflowVersion={workflowVersion}
      />
      {error !== undefined && errorHandling === "inline" && <Box>{error}</Box>}
    </>
  );
}

export function CreateIncidentWorkItemPage({
  errorHandling,
}: CreateIncidentWorkItemPageProps) {
  const { user, availableOrganisations } = useRouteLoaderData(
    "root",
  ) as RootLoaderData;

  return (
    <CreateIncidentWorkItem
      errorHandling={errorHandling}
      user={user}
      availableOrganisations={availableOrganisations}
    />
  );
}
