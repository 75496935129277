import type { ActionInput } from "../ActionInput.js";
import {
  hasArrivedAtActivePlaceOfSafety,
  hasPlaceOfSafety,
  hasTriageForm,
  incidentIsActive,
  section136FormCompleted,
  userIsIncidentOwner,
} from "../helpers.js";

type ShareFormAction = {
  canShareForm: boolean;
};

function canShareForm(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput) &&
    hasPlaceOfSafety(actionInput) &&
    hasArrivedAtActivePlaceOfSafety(actionInput) &&
    section136FormCompleted(actionInput)
  );
}

export function calculateShareActions(
  actionInput: ActionInput,
): ShareFormAction {
  return {
    canShareForm: canShareForm(actionInput),
  };
}
