import { z } from "zod";
import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/MultiPageFormBuilder.js";
import { dateTimeSchema, freeTextSchema } from "../schemas.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysRequired({
    fieldName: "custodyNumber",
    type: "textbox",
    schema: z.any().optional(),
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysRequired({
    fieldName: "dateTimeOfDetention",
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    schema: dateTimeSchema,
  })
  .withFieldAlwaysRequired({
    fieldName: "responsibleForDetention",
    type: "textbox",
    schema: freeTextSchema,
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysRequired({
    fieldName: "detentionTookPlace",
    type: "textbox",
    schema: freeTextSchema,
    label: "detentionDetails.detentionTookPlace",
  })
  .withPage(["custodyNumber"])
  .withPage(["dateTimeOfDetention"])
  .withPage(["responsibleForDetention"])
  .withPage(["detentionTookPlace"])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
