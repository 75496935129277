export default {
  role: {
    officer: "BTP Officer",
    commandunit: "Sub-Divisional Command Unit",
  },
  incidentWorkItem: {
    commandUnit: "Sub-Division",
  },
  userProfile: {
    shoulderNumber: "Police Number",
  },
};
