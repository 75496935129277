import React, { useMemo } from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import { v4 } from "uuid";
import api from "../../api/index.js";
import { PlacesOfSafetyContext } from "../../components/FormBuilder/PlaceOfSafetyPicker/PlaceOfSafetyContext.js";
import {
  RecordPlaceOfSafetyForm,
  RecordPlaceOfSafetyFormSkeleton,
} from "../../forms/RecordPlaceOfSafety/RecordPlaceOfSafetyForm.js";
import { createRoute } from "../../routing/createRoute.js";
import {
  IncidentWorkItemLoaderData,
  IncidentWorkItemLoaderDeferredData,
} from "../../routing/loaders/incidentWorkItem.js";
import {
  RootLoaderData,
  RootLoaderDeferredData,
} from "../../routing/loaders/root.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type RecordPlaceOfSafetyPageProps = Record<string, unknown>;

export function RecordPlaceOfSafetyPage(_props: RecordPlaceOfSafetyPageProps) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const { placesOfSafety } = useRouteLoaderData(
    "root",
  ) as RootLoaderDeferredData;

  const [error, setError] = React.useState<string | undefined>(undefined);

  const formId = useMemo(() => IncidentFormIdSchema.parse(v4()), []);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;
    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <RecordPlaceOfSafetyFormSkeleton />
        ) : (
          <RecordPlaceOfSafetyForm
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }

                // Create a form
                const createFormResponse =
                  await api.incidentWorkItem.createPlaceOfSafetyForm(
                    incidentWorkItemId,
                    formId,
                  );
                if (createFormResponse.status !== 204) {
                  throw Error(JSON.stringify(createFormResponse.data.message));
                }

                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    formId,
                    FormSection.recordPlaceOfSafetyMain,
                    values,
                    true,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                // Mark it as the active Place of Safety
                const updateActivePlaceOfSafetyFormResponse =
                  await api.incidentWorkItem.updateActivePlaceOfSafety(
                    incidentWorkItemId,
                    formId,
                  );
                if (updateActivePlaceOfSafetyFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(
                      updateActivePlaceOfSafetyFormResponse.data.message,
                    ),
                  );
                }

                revalidator.revalidate();
                navigate(createRoute.incidentProgressPage(incidentWorkItemId));
                return;
              } catch (error) {
                setError(
                  "Failed to create incident work item: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() =>
              navigate(createRoute.incidentProgressPage(incidentWorkItemId))
            }
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await
        resolve={Promise.all([placesOfSafety, incidentWorkItem])}
        errorElement={<ErrorComponent />}
      >
        {([placesOfSafety, incidentWorkItem]: [
          RootLoaderData["placesOfSafety"],
          IncidentWorkItemLoaderData["incidentWorkItem"],
        ]) => {
          return (
            <PlacesOfSafetyContext.Provider
              value={{
                placesOfSafety: placesOfSafety.map((placeOfSafety) => ({
                  aspirePlaceOfSafetyId: placeOfSafety.id,
                  name: placeOfSafety.name,
                  // TODO: ASP-2729 Improve the incoming data type to match the information we need
                  address: `${placeOfSafety.name} Address`,
                  postalCode: `${placeOfSafety.name} Postal Code`,
                })),
              }}
            >
              {render(incidentWorkItem)}
            </PlacesOfSafetyContext.Provider>
          );
        }}
      </Await>
    </React.Suspense>
  );
}
