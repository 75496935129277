import { Button, Input, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import api from "../../api/index.js";
import { PopupDialog } from "../../components/Dialog/PopupDialog.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";

export type ShareModalProps = {
  incidentWorkItemId: string;
  isOpen: boolean;
  onClose: () => void;
};

export function ShareModal(props: ShareModalProps) {
  const theme = useTheme();
  const notifications = useToastNotifications();
  const [email, setEmail] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const handleShare = React.useCallback(() => {
    if (!email || !props.incidentWorkItemId) return;
    setSubmitting(true);
    api.incidentWorkItem.share(props.incidentWorkItemId, email).then((res) => {
      if (res.status === 204) {
        notifications.show("Incident shared successfully", {
          severity: "success",
        });
        props.onClose();
      }
    });
  }, [email, props, notifications]);

  return (
    <PopupDialog
      open={props.isOpen}
      onClose={props.onClose}
      title="Share incident"
    >
      <Stack gap={"2rem"}>
        <Typography
          sx={{
            fontSize: theme.spacing(2),
            fontWeight: 500,
          }}
        >
          Share to NHS
        </Typography>
        <Input
          placeholder="Enter email address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={handleShare}
          disabled={submitting || !email}
        >
          Share
        </Button>
      </Stack>
    </PopupDialog>
  );
}
