import type { ActionInput } from "./ActionInput.js";
import { calculateArrivalActions } from "./actions/arrival.js";
import { calculateEndIncidentActions } from "./actions/endIncident.js";
import { calculatePlaceOfSafetyActions } from "./actions/placeOfSafety.js";
import { calculateSection136Actions } from "./actions/section136.js";
import { calculateShareActions } from "./actions/share.js";
import { calculateTransferActions } from "./actions/transfer.js";
import { calculateTriageActions } from "./actions/triage.js";

export const defaultActions: IncidentWorkItemActionsObject = {
  transfers: {
    canInitiate: false,
    canRevoke: false,
    canAcceptReject: false,
  },
  section136: {
    canStart: false,
    canEdit: false,
  },
  endIncident: {
    canEnd: false,
  },
  triage: {
    canStart: false,
    canEdit: false,
  },
  placeOfSafety: {
    canAdd: false,
    canEdit: false,
  },
  arrival: {
    canMarkArrival: false,
  },
  share: {
    canShareForm: false,
  },
};

export function calculateActions(
  actionInput: ActionInput,
): IncidentWorkItemActionsObject {
  return {
    transfers: calculateTransferActions(actionInput),
    section136: calculateSection136Actions(actionInput),
    endIncident: calculateEndIncidentActions(actionInput),
    triage: calculateTriageActions(actionInput),
    placeOfSafety: calculatePlaceOfSafetyActions(actionInput),
    arrival: calculateArrivalActions(actionInput),
    share: calculateShareActions(actionInput),
  };
}

export type IncidentWorkItemActionsObject = {
  transfers: {
    canInitiate: boolean;
    canRevoke: boolean;
    canAcceptReject: boolean;
  };
  section136: {
    canStart: boolean;
    canEdit: boolean;
  };
  endIncident: {
    canEnd: boolean;
  };
  triage: {
    canStart: boolean;
    canEdit: boolean;
  };
  placeOfSafety: {
    canAdd: boolean;
    canEdit: boolean;
  };
  arrival: {
    canMarkArrival: boolean;
  };
  share: {
    canShareForm: boolean;
  };
};
