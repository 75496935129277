import {
  CreateIncidentWorkItemRequest,
  FormSection,
  GetIncidentWorkItemResponse,
  IncidentFormId,
  IncidentWorkItemId,
  IncidentWorkItemIdUnbranded,
  IncidentWorkItemWithContext,
} from "@vision/common";
import { AxiosInstance } from "axios";
import { makeUseAxios } from "axios-hooks";

export const config = {
  incidentWorkItem: {
    get: (id: IncidentWorkItemId) => ({
      url: `/api/incident-work-item/${id}`,
      method: "get",
    }),
    getMyActiveIncidentWorkItems: () => ({
      url: `/api/incident-work-item`,
      method: "get",
    }),
    create: (
      id: IncidentWorkItemId,
      creatIncidentWorkItemReq: CreateIncidentWorkItemRequest,
    ) => ({
      url: `/api/incident-work-item/${id}`,
      method: "post",
      data: creatIncidentWorkItemReq,
    }),
    share: (id: IncidentWorkItemIdUnbranded, email: string) => ({
      url: `/api/incident-work-item/${id}/share`,
      method: "post",
      data: { email },
    }),
    updateData(
      id: IncidentWorkItemId,
      formId: IncidentFormId,
      section: FormSection,
      data: Record<string, unknown>,
      validateData: boolean,
    ) {
      return {
        url: `/api/incident-work-item/${id}/form/${formId}/section/${section}`,
        method: "post",
        data,
        params: { validateData },
      };
    },
    create136Form(id: IncidentWorkItemId) {
      return {
        url: `/api/incident-work-item/${id}/form/136`,
        method: "post",
      };
    },
    createPlaceOfSafetyForm(
      workItemId: IncidentWorkItemId,
      formId: IncidentFormId,
    ) {
      return {
        url: `/api/incident-work-item/${workItemId}/form/place-of-safety/${formId}`,
        method: "post",
      };
    },
    updateActivePlaceOfSafety(
      incidentWorkItemId: IncidentWorkItemId,
      placeOfSafetyFormId: IncidentFormId,
    ) {
      return {
        url: `/api/incident-work-item/${incidentWorkItemId}/active-place-of-safety`,
        method: "post",
        data: { formId: placeOfSafetyFormId },
      };
    },
  },
};

export const api = (apiAxios: AxiosInstance) => {
  const useApiAxios = makeUseAxios({ axios: apiAxios, cache: false });

  return {
    incidentWorkItem: {
      get: (id: IncidentWorkItemId) =>
        apiAxios.request<IncidentWorkItemWithContext | null>(
          config.incidentWorkItem.get(id),
        ),
      getMyActiveIncidentWorkItems: () =>
        apiAxios.request<GetIncidentWorkItemResponse>(
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        ),

      create: (
        id: IncidentWorkItemId,
        creatIncidentWorkItemReq: CreateIncidentWorkItemRequest,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.create(id, creatIncidentWorkItemReq),
        ),

      share: (id: IncidentWorkItemIdUnbranded, email: string) =>
        apiAxios.request(config.incidentWorkItem.share(id, email)),

      updateData: (
        id: IncidentWorkItemId,
        formId: IncidentFormId,
        section: FormSection,
        data: Record<string, unknown>,
        validateData: boolean,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateData(
            id,
            formId,
            section,
            data,
            validateData,
          ),
        ),

      create136Form: (id: IncidentWorkItemId) =>
        apiAxios.request(config.incidentWorkItem.create136Form(id)),

      createPlaceOfSafetyForm: (
        workItemId: IncidentWorkItemId,
        formId: IncidentFormId,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.createPlaceOfSafetyForm(workItemId, formId),
        ),

      updateActivePlaceOfSafety: (
        incidentWorkItemId: IncidentWorkItemId,
        placeOfSafetyFormId: IncidentFormId,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateActivePlaceOfSafety(
            incidentWorkItemId,
            placeOfSafetyFormId,
          ),
        ),
    },
    incidentWorkItemHooks: {
      get: function useGetIncidentWorkItem(id: IncidentWorkItemId) {
        return useApiAxios<IncidentWorkItemWithContext | null>(
          config.incidentWorkItem.get(id),
        );
      },
      getMyActiveIncidentWorkItems: function useGetMyActiveIncidentWorkItem() {
        return useApiAxios<GetIncidentWorkItemResponse>(
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        );
      },
    },
  };
};
