import React, { useMemo } from "react";

import {
  createInitialValues,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

type TriageIncidentSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["triageIncident"]["formDefinition"]["sections"][0]["formSchema"]
>;

///

export function TriageIncidentFormSkeleton() {
  return <MultiPageFormSkeleton headerText={"triageIncident.title"} />;
}

///

export type TriageIncidentFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: TriageIncidentSchema<T>) => void;
  onCancel: () => void;
  workflowVersion: T;
  initialValues?: TriageIncidentSchema<T>;
};

///

export function TriageIncidentForm<T extends WorkflowVersions>(
  props: TriageIncidentFormProps<T>,
) {
  const workflow = workflows[props.workflowVersion];
  const formSchema =
    workflow.triageIncident.formDefinition.sections[0].formSchema;
  const strictFieldSchemas =
    workflow.triageIncident.formDefinition.sections[0].strictFieldSchemas;

  const initialValuesOrDefaults = useMemo(
    () =>
      props.initialValues ??
      (createInitialValues(strictFieldSchemas) as z.infer<typeof formSchema>),
    [props.initialValues, strictFieldSchemas],
  );

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"triageIncident.title"}
      schema={formSchema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={initialValuesOrDefaults}
      pages={
        // This form only ever has one section
        workflow.triageIncident.formDefinition.sections[0].pages
      }
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
