import { type ZodType, type ZodTypeAny } from "zod";
import type { TranslationKey } from "../@types/react-i18next.js";
import type { FormBuilderField } from "../formBuilderFields/fields.js";
import type { VisionPdfTemplate } from "../types/VisionPdfTemplate.js";
import type {
  IncidentFormType,
  IncidentWorkItemWithContextUnbranded,
} from "../types/workItem.js";
import type { WorkflowVersions } from "../types/workflowVersion.js";
import type {
  EthnicityType,
  GenderType,
  SelfDeclaredEthnicityType,
} from "./schemas.js";

export type DetentionDetailsMinimumFields = {
  custodyNumber: string;
  dateTimeOfDetention: string;
  responsibleForDetention: string;
  detentionTookPlace: string;
};

export type CreateIncidentMinimumFields = {
  dispatchNumber: string;
  patientForename: string;
  patientSurname: string;
  commandUnit: string;
};

export type ValueAndLabel<T> = {
  value: T;
  label: string;
};

export type TriageIncidentMinimumFields = {
  isHealthcareProfessionalConsulted: ValueAndLabel<"yes" | "no">;
  whatWillYouDo: ValueAndLabel<"136" | "5-6" | "voluntary" | "other">;
};

export type PersonDetailsMinimumFields = {
  patientAddress: string;
  dateOfBirth: string;
  gender: ValueAndLabel<GenderType>;
  ethnicAppearance: ValueAndLabel<EthnicityType>;
  selfDeclaredEthnicity: ValueAndLabel<SelfDeclaredEthnicityType>;
  hasRelativeOrFriend: ValueAndLabel<"yes" | "no">;
};

export type InformationForHealthcareMinimumFields = {
  wasHealthcareProvided: ValueAndLabel<"yes" | "no">;
  hasPersonBeenSearched: ValueAndLabel<"yes" | "no">;
  hasAdditionalRiskFactors: ValueAndLabel<"yes" | "no">;
};

export type RecordPlaceOfSafetyMinimumFields = {
  placeOfSafety: {
    aspirePlaceOfSafetyId?: string;
    name: string;
    address: string;
    postalCode: string;
  };
};

export type RecordPlaceOfSafetyArrivalMinimumFields = {
  arrivalDateTime: string;
};

export enum FormSection {
  personDetails = "personDetails",
  detentionDetails = "detentionDetails",
  informationForHealthcare = "informationForHealthcare",
  triageMain = "triageMain",
  createIncidentMain = "createIncidentMain",
  recordPlaceOfSafetyMain = "recordPlaceOfSafetyMain",
  recordPlaceOfSafetyArrival = "recordPlaceOfSafetyArrival",
}
export type MinimumFieldsPerSection = {
  [FormSection.personDetails]: PersonDetailsMinimumFields;
  [FormSection.detentionDetails]: DetentionDetailsMinimumFields;
  [FormSection.informationForHealthcare]: InformationForHealthcareMinimumFields;
  [FormSection.triageMain]: TriageIncidentMinimumFields;
  [FormSection.createIncidentMain]: CreateIncidentMinimumFields;
  [FormSection.recordPlaceOfSafetyMain]: RecordPlaceOfSafetyMinimumFields;
  [FormSection.recordPlaceOfSafetyArrival]: RecordPlaceOfSafetyArrivalMinimumFields;
};

export type IncidentFormSections = {
  [IncidentFormType.createIncident]: FormSection.createIncidentMain;
  [IncidentFormType.triage]: FormSection.triageMain;
  [IncidentFormType.recordPlaceOfSafety]:
    | FormSection.recordPlaceOfSafetyMain
    | FormSection.recordPlaceOfSafetyArrival;
  [IncidentFormType.section136]:
    | FormSection.personDetails
    | FormSection.detentionDetails
    | FormSection.informationForHealthcare;
};

export type FormSectionDefinition<
  Section extends FormSection,
  DataType extends MinimumFieldsPerSection[Section],
> = {
  id: Section;
  name: TranslationKey;
  strictFieldSchemas: Record<string, ZodTypeAny>;
  formSchema: ZodType<DataType>;
  autosave: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages: { fields: readonly FormBuilderField<DataType, any>[] }[];
};

export type FormDefinition<
  SectionDefinitions extends FormSectionDefinition<
    FormSection,
    // Couldn't figure out how to avoid the any here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >[],
> = {
  sections: SectionDefinitions;
};

export type WorkflowPdfInputData<
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = {
  triage: TTriageIncident;

  section136: {
    personDetails: TPersonDetails;
    detentionDetails: TDetentionDetails;
    informationForHealthcare: TInformationForHealthcare;
  };

  incidentWorkItem: IncidentWorkItemWithContextUnbranded;
  // TODO: other forms/sections and metadata
};

export type WorkflowPdfTemplate<
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends Partial<PersonDetailsMinimumFields>,
  TDetentionDetails extends Partial<DetentionDetailsMinimumFields>,
  TInformationForHealthcare extends
    Partial<InformationForHealthcareMinimumFields>,
> = VisionPdfTemplate<
  WorkflowPdfInputData<
    TTriageIncident,
    TPersonDetails,
    TDetentionDetails,
    TInformationForHealthcare
  >
>;

export type Workflow<
  TCreateIncident extends CreateIncidentMinimumFields,
  TTriageIncident extends TriageIncidentMinimumFields,
  TPersonDetails extends PersonDetailsMinimumFields,
  TDetentionDetails extends DetentionDetailsMinimumFields,
  TInformationForHealthcare extends InformationForHealthcareMinimumFields,
  TRecordPlaceOfSafety extends RecordPlaceOfSafetyMinimumFields,
  TRecordPlaceOfSafetyArrival extends RecordPlaceOfSafetyArrivalMinimumFields,
> = {
  workflowVersion: WorkflowVersions;
  [IncidentFormType.createIncident]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.createIncidentMain, TCreateIncident>]
    >;
  };
  [IncidentFormType.triage]: {
    formDefinition: FormDefinition<
      [FormSectionDefinition<FormSection.triageMain, TTriageIncident>]
    >;
  };
  [IncidentFormType.recordPlaceOfSafety]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyMain,
          TRecordPlaceOfSafety
        >,
        FormSectionDefinition<
          FormSection.recordPlaceOfSafetyArrival,
          TRecordPlaceOfSafetyArrival
        >,
      ]
    >;
  };
  [IncidentFormType.section136]: {
    formDefinition: FormDefinition<
      [
        FormSectionDefinition<FormSection.personDetails, TPersonDetails>,
        FormSectionDefinition<FormSection.detentionDetails, TDetentionDetails>,
        FormSectionDefinition<
          FormSection.informationForHealthcare,
          TInformationForHealthcare
        >,
      ]
    >;

    pdfTemplate: WorkflowPdfTemplate<
      TTriageIncident,
      TPersonDetails,
      TDetentionDetails,
      TInformationForHealthcare
    >;
  };
};
