import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

type CheckboxOption = {
  label: TranslationKey;
  value: string;
};

export type CheckboxGroupProps = {
  fieldName: string;
  label: TranslationKey;
  options: CheckboxOption[];
};

export const CheckboxGroup = ({
  fieldName,
  label,
  options,
}: CheckboxGroupProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<string[]>(fieldName);

  const handleChange = (value: string) => {
    const newValue = field.value.includes(value)
      ? field.value.filter((v) => v !== value)
      : [...field.value, value];
    helpers.setValue(newValue);
  };

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <FormLabel data-testid="checkbox-group-label" label={label} />

      <Box display="flex" flexDirection="column" gap={1}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={field.value.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
            }
            label={t(option.label)}
          />
        ))}
      </Box>

      {meta.touched && meta.error && <FormError error={meta.error} />}
    </Box>
  );
};
