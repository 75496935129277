import { Done, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { defined, parsed } from "@thalamos/common";
import {
  calculateActions,
  defaultActions,
  FormSection,
  IncidentFormIdSchema,
  IncidentFormType,
  IncidentType,
  IncidentWorkItemActionsObject,
  IncidentWorkItemId,
  IncidentWorkItemWithContext,
  RecordPlaceOfSafetyMinimumFields,
  TranslationKey,
  workflows,
} from "@vision/common";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { ok, safeTry } from "neverthrow";
import React from "react";
import { useTranslation } from "react-i18next";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";
import IncidentBanner from "../../components/IncidentBanner/IncidentBanner.js";
import { TypographyI18N } from "../../components/index.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { InitiateTransferModal } from "../../modals/InitiateTransfer/InitiateTransfer.js";
import { createRoute } from "../../routing/createRoute.js";
import {
  IncidentWorkItemLoaderData,
  IncidentWorkItemLoaderDeferredData,
} from "../../routing/loaders/incidentWorkItem.js";
import { RootLoaderData } from "../../routing/loaders/root.js";
import { Error } from "../Error/Error.js";
import { ShareModal } from "./ShareModal.js";

type StepData = {
  label: TranslationKey;
  completed: boolean;
  onClick?: () => void;
};

type FormProgressProps = {
  incidentWorkItemId?: IncidentWorkItemId;
  steps: StepData[];
  placeOfSafety: string;
  arrivalUrl?: string;
  actions: IncidentWorkItemActionsObject;
};

const FormProgressSteps = ({ steps }: { steps: StepData[] }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mx: { xs: 0, sm: 10 }, pt: 2 }}>
      <Stepper orientation="vertical" nonLinear>
        {steps.map((step, index) => (
          <Step key={index} active={true} completed={step.completed}>
            <StepLabel
              icon={
                step.completed ? (
                  <Done color="success" />
                ) : (
                  <RadioButtonUncheckedOutlined color="action" />
                )
              }
              StepIconProps={{
                sx: {
                  color: step.completed
                    ? "success.main"
                    : theme.palette.common.black,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TypographyI18N
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "0.8125rem", sm: "1rem" },
                    color: theme.palette.common.black,
                  }}
                  translationKey={step.label}
                />
                <TypographyI18N
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "0.8125rem",
                  }}
                  data-testid={`action-button-${index}`}
                  onClick={step.onClick}
                  translationKey={
                    step.completed
                      ? "formProgressPage.view"
                      : "formProgressPage.add"
                  }
                />
              </Box>
              <Divider />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export const getIncidentStatus = (
  incidentWorkItem: IncidentWorkItemWithContext,
  t: TFunction,
  actions: IncidentWorkItemActionsObject,
) => {
  if (actions.section136.canStart) return t("formProgressPage.s136InProgress");

  return incidentWorkItem?.incidentWorkItem.status ?? t("unknown");
};

export const extractIncidentDetails = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
  t: TFunction,
  actions: IncidentWorkItemActionsObject,
) => {
  if (incidentWorkItem === undefined) {
    return { fullName: t("unknown"), incidentStatus: t("unknown") };
  }
  const patientForename = incidentWorkItem.incidentWorkItem.patientForename;
  const patientSurname = incidentWorkItem.incidentWorkItem.patientSurname;
  const incidentStatus = getIncidentStatus(incidentWorkItem, t, actions);

  return { fullName: `${patientForename} ${patientSurname}`, incidentStatus };
};

export function FormProgressWrapper() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useRouteLoaderData("root") as RootLoaderData;
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;

  const render = ({
    user,
    incidentWorkItem,
  }: {
    user: RootLoaderData["user"] | undefined;
    incidentWorkItem: IncidentWorkItemWithContext | undefined;
  }) => {
    if ([user, incidentWorkItem].some((x) => x === undefined)) {
      return <CircularProgress />;
    }

    const actions =
      incidentWorkItem && user
        ? calculateActions({ incidentWorkItem, user, asOfDateTime: dayjs() })
        : defaultActions;

    const { fullName, incidentStatus } = extractIncidentDetails(
      incidentWorkItem,
      t,
      actions,
    );

    const currentWorkFlowVersion =
      incidentWorkItem?.incidentWorkItem.workflowVersion;

    const currentWorkItemForm = (incidentWorkItem?.forms ?? []).find(
      (form) =>
        form.incidentWorkItemId === incidentWorkItem?.incidentWorkItem.id &&
        form.formDefinitionVersion === currentWorkFlowVersion &&
        form.status === "active",
    );

    if (currentWorkItemForm === undefined) {
      return <Error />;
    }

    const loadWorkFlow = workflows[currentWorkItemForm.formDefinitionVersion];

    // TODO - we need to dynamically load the current workflow based on what flow we have chosen from the triage form
    const loadCurrentWorkFlow =
      loadWorkFlow?.[IncidentType.section136]?.formDefinition ?? {};
    const triageWorkFlow =
      loadWorkFlow?.[IncidentFormType.triage]?.formDefinition ?? {};
    const createIncidentWorkFlow =
      loadWorkFlow?.[IncidentFormType.createIncident]?.formDefinition ?? {};

    // Merge createIncidentWorkFlow and triageWorkFlow sections with a `+` between their names
    const mergedCreateAndTriageSections = (() => {
      const triageSections = triageWorkFlow?.sections ?? [];
      const createSections = createIncidentWorkFlow?.sections ?? [];
      const triageComplete = actions.triage.canEdit;

      // Combine sections by index, with a `+` for overlapping sections
      const combinedSections = triageSections.map((section, index) => {
        const createSection = createSections[index];
        // if theres a triage section and create section, combine their names using a translation key
        if (createSection && section) {
          return {
            name: "incidentFormSections.incidentAndTriage" as TranslationKey,
            completed: triageComplete,
          };
        }
        return {
          name: section.name,
          completed: triageComplete,
        };
      });

      return combinedSections;
    })();

    const is136FormSectionComplete = (sectionId: string) => {
      const forms = incidentWorkItem?.forms;
      if (!forms) return false;

      const form = forms.find(
        (form) => form.formType === IncidentFormType.section136,
      );
      if (!form || !form.sections) return false;

      switch (sectionId) {
        case FormSection.personDetails: {
          const section = form.sections.find(
            (section) => section.id === FormSection.personDetails,
          );
          return section ? section?.data !== null : false;
        }
        case FormSection.detentionDetails: {
          const section = form.sections.find(
            (section) => section.id === FormSection.detentionDetails,
          );
          return section ? section?.data !== null : false;
        }
        case FormSection.informationForHealthcare: {
          const section = form.sections.find(
            (section) => section.id === FormSection.informationForHealthcare,
          );
          return section ? section?.data !== null : false;
        }
        default:
          return false;
      }
    };

    const get136FormSectionClickHandler = (sectionId: string) => {
      if (!incidentWorkItem) {
        return null;
      }

      switch (sectionId) {
        case FormSection.personDetails:
          navigate(
            createRoute.incidentPersonDetailsPage(
              incidentWorkItem.incidentWorkItem.id,
            ),
          );
          break;
        case FormSection.detentionDetails:
          navigate(
            createRoute.incidentDetentionDetailsPage(
              incidentWorkItem.incidentWorkItem.id,
            ),
          );
          break;
        case FormSection.informationForHealthcare:
          navigate(
            createRoute.incidentInformationForHealthcarePage(
              incidentWorkItem.incidentWorkItem.id,
            ),
          );
          break;
        default:
          return null;
      }
    };
    // Combine all workflows into steps
    const currentSteps = [
      ...mergedCreateAndTriageSections.map(
        (section) =>
          ({
            label: section.name,
            completed: section.completed,
            onClick: () => {
              navigate(
                incidentWorkItem
                  ? createRoute.incidentTriagePage(
                      incidentWorkItem.incidentWorkItem.id,
                    )
                  : createRoute.home(),
              );
            },
          }) satisfies StepData,
      ),
      ...(loadCurrentWorkFlow?.sections.map(
        (section) =>
          ({
            label: section.name,
            completed: is136FormSectionComplete(section.id),
            onClick: () => {
              return get136FormSectionClickHandler(section.id);
            },
          }) satisfies StepData,
      ) ?? []),
    ];

    // Calculate key details about the active Place of Safety
    const activePlaceOfSafety = (() => {
      const result = safeTry(function* () {
        const posFormId = yield* defined(
          incidentWorkItem?.incidentWorkItem?.activePlaceOfSafetyFormId,
        );

        const posForm = yield* defined(
          (incidentWorkItem?.forms ?? [])
            .filter(
              (form) => form.formType === IncidentFormType.recordPlaceOfSafety,
            )
            .find((form) => form.id === posFormId),
        );

        const posFormSectionMain = yield* defined(
          posForm.sections.find(
            (section) => section.id === FormSection.recordPlaceOfSafetyMain,
          ),
        );

        const posFormSectionArrival = yield* defined(
          posForm.sections.find(
            (section) => section.id === FormSection.recordPlaceOfSafetyArrival,
          ),
        );

        const posFormSectionArrivalId = yield* parsed(
          IncidentFormIdSchema,
          posFormSectionArrival.incidentFormId,
        );

        const posName = yield* defined(
          (
            (posFormSectionMain.data ?? undefined) as
              | RecordPlaceOfSafetyMinimumFields
              | undefined
          )?.placeOfSafety?.name,
        );

        return ok({
          name: posName,
          arrivalFormId: posFormSectionArrivalId,
        });
      });
      return result.unwrapOr(undefined);
    })();

    return (
      <Stack
        data-testid="form-progress"
        sx={{
          [theme.breakpoints.up("md")]: {
            padding: "5rem",
          },
          [theme.breakpoints.down("md")]: {
            padding: "0.5rem",
          },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Paper
          sx={{
            padding: { xs: 0, sm: 3 },
            mx: "auto",
            boxShadow: { xs: 0, sm: 3 },
            maxWidth: { xs: "100%", sm: "80%" },
            width: "100%",
          }}
        >
          {/* Incident Banner */}
          <IncidentBanner
            name={fullName}
            incidentStatus={incidentStatus}
            isViewTimelineDisabled={!actions.triage.canEdit} // can view timeline once triage is complete
            isViewShareFormDisabled={!actions.share.canShareForm}
          />
          {/* Form Progress */}
          <FormProgress
            incidentWorkItemId={incidentWorkItem?.incidentWorkItem.id}
            steps={currentSteps}
            placeOfSafety={activePlaceOfSafety?.name ?? "Not known"}
            arrivalUrl={
              incidentWorkItem?.incidentWorkItem?.id !== undefined &&
              activePlaceOfSafety !== undefined &&
              actions.arrival.canMarkArrival
                ? createRoute.incidentRecordPlaceOfSafetyArrivalPage(
                    incidentWorkItem.incidentWorkItem.id,
                    activePlaceOfSafety.arrivalFormId,
                  )
                : undefined
            }
            actions={actions}
          />
        </Paper>
      </Stack>
    );
  };

  return (
    <React.Suspense
      fallback={render({ user: undefined, incidentWorkItem: undefined })}
    >
      <Await
        resolve={Promise.all([user, incidentWorkItem])}
        errorElement={<Error />}
      >
        {([user, incidentWorkItem]: [
          RootLoaderData["user"],
          IncidentWorkItemLoaderData["incidentWorkItem"],
        ]) => render({ user, incidentWorkItem })}
      </Await>
    </React.Suspense>
  );
}

export function FormProgress({
  incidentWorkItemId,
  steps,
  placeOfSafety,
  arrivalUrl = undefined,
  actions,
}: FormProgressProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showTransferModal, setShowTransferModal] = React.useState(false);
  const notifications = useToastNotifications();
  const { t } = useTranslation();
  const [showShareModal, setShowShareModal] = React.useState(false);

  return (
    <>
      {/* Form Progress Steps */}
      <Box sx={{ px: { xs: 1, sm: 2 } }}>
        <FormProgressSteps steps={steps} />
      </Box>

      {/* Place of Safety Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <ShareModal
          isOpen={showShareModal}
          onClose={() => setShowShareModal(false)}
          incidentWorkItemId={incidentWorkItemId ?? ""}
        />
        <Grid
          container
          sx={{
            backgroundColor: "common.background",
            maxWidth: { xs: "100%" },
            p: 1.5,
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Grid container>
                <Grid item xs={9}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TypographyI18N
                      sx={{ fontSize: "0.75rem" }}
                      color="text.secondary"
                      translationKey="formProgressPage.activePlaceOfSafety"
                    />
                    <Typography
                      sx={{
                        marginLeft: "0.25rem",
                        fontWeight: "bold",
                        fontSize: { xs: "0.8125rem", sm: "1rem" },
                      }}
                    >
                      {placeOfSafety}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TypographyI18N
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: 500,
                      fontSize: "0.8125rem",
                      color: theme.palette.primary.main,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    translationKey="formProgressPage.add"
                    onClick={() => {
                      if (incidentWorkItemId !== undefined) {
                        navigate(
                          createRoute.incidentRecordPlaceOfSafetyPage(
                            incidentWorkItemId,
                          ),
                        );
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Arrival and Handover Buttons Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Grid
          container
          sx={{
            maxWidth: { xs: "100%" },
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid container justifyContent="space-around">
            {/* Arrival Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                  translationKey="formProgressPage.arrival"
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmArrival"
                />
                <Button
                  variant="contained"
                  data-testid="arrival-button"
                  disabled={arrivalUrl === undefined}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                  onClick={() => {
                    if (arrivalUrl !== undefined) {
                      navigate(arrivalUrl);
                    }
                  }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.arrival"
                  />
                </Button>
              </Stack>
            </Grid>

            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  minHeight: { xs: "100%", sm: "300px" },
                  height: "100%",
                  borderColor: "divider",
                }}
              />
            </Grid>

            {/* Handover Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  translationKey="formProgressPage.handoverAndShare"
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmHandover"
                />
                <Button
                  variant="contained"
                  data-testid="handover-button"
                  disabled={!actions.share.canShareForm}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                  onClick={async () => {
                    setShowShareModal(true);
                  }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.handover"
                  />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* End and Transfer Incident Buttons */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1000,
          backgroundColor: "common.background",
          borderRadius: "9px",
        }}
      >
        <Grid
          container
          sx={{
            mt: 3,
            p: 2,
            flexDirection: "row",
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "100%",
                fontSize: "0.75rem",
                backgroundColor: "white",
              }}
              disabled={!actions.endIncident.canEnd}
            >
              <TypographyI18N
                variant="caption"
                sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                translationKey="formProgressPage.endIncident"
              />
            </Button>
          </Grid>

          <Grid
            item
            xs={4}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                fontSize: "0.75rem",
                backgroundColor: "white",
              }}
              disabled={!actions.transfers.canInitiate}
              onClick={() => setShowTransferModal(true)}
            >
              <TypographyI18N
                variant="caption"
                sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                translationKey="formProgressPage.transfer"
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <InitiateTransferModal
        open={showTransferModal}
        onClose={() => setShowTransferModal(false)}
        onConfirm={(email) => {
          // TODO - Add initiate transfer api logic here
          notifications.show(
            t("transfer.initiateTransfer.confirmToast", { email }),
            {
              severity: "success",
            },
          );
          setShowTransferModal(false);
        }}
      />
    </>
  );
}
