import type { ActionInput } from "../ActionInput.js";
import {
  hasTriageForm,
  incidentIsActive,
  userIsIncidentOwner,
} from "../helpers.js";

type StartTriageAction = {
  canStart: boolean;
};

type EditTriageAction = {
  canEdit: boolean;
};

type TriageActions = StartTriageAction & EditTriageAction;

function canStartTriage(actionInput: ActionInput): boolean {
  return incidentIsActive(actionInput) && userIsIncidentOwner(actionInput);
}

function canEditTriage(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput)
  );
}

export function calculateTriageActions(
  actionInput: ActionInput,
): TriageActions {
  return {
    canStart: canStartTriage(actionInput),
    canEdit: canEditTriage(actionInput),
  };
}
