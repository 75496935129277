import React from "react";

import {
  FormSection,
  IncidentFormType,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import api from "../../api/index.js";
import {
  InformationForHealthcareForm,
  InformationForHealthcareFormSkeleton,
} from "../../forms/InfoForHealth/InformationForHealthcareForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type InformationForHealthcarePageProps = Record<string, unknown>;

export function InformationForHealthcarePage(
  _props: InformationForHealthcarePageProps,
) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }
    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const section136Form = incidentWorkItem?.forms?.find?.(
      (form) => form.formType === IncidentFormType.section136,
    );

    const section136FormId = section136Form?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;

    return (
      <>
        {incidentWorkItemId === undefined ||
        section136FormId === undefined ||
        workflowVersion === undefined ? (
          <InformationForHealthcareFormSkeleton />
        ) : (
          <InformationForHealthcareForm
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }
                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  section136FormId,
                  FormSection.informationForHealthcare,
                  values,
                  true,
                );
                if (response.status === 204) {
                  revalidator.revalidate();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.informationForHealthcare,
                    },
                  });
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to update 136 form: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => {
              navigate(createRoute.incidentProgressPage(incidentWorkItemId));
            }}
            initialValues={
              section136Form?.sections?.find?.(
                (section) =>
                  section.id === FormSection.informationForHealthcare,
              )?.data
            }
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
