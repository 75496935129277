import z, { ZodType } from "zod";

/**
 * An Email Address
 */
export type EmailUnbranded = string;
export const EmailSchema = z
  .string()
  .email()
  .brand(Symbol("Email")) satisfies ZodType<EmailUnbranded>;
export type Email = z.infer<typeof EmailSchema>;
