import React from "react";

import { Box, FormControl, MenuItem, Select, useTheme } from "@mui/material";
import {
  AvailableOrganisationUnbranded,
  OrganisationFormBuilderField,
  OrganisationRoles,
  TranslationKey,
} from "@vision/common";
import { useField } from "formik";
import { FormLabel } from "../FormLabel/FormLabel.js";

export type OrganisationDropdownProps<
  Form extends Record<string, unknown>,
  _Field extends keyof Form,
> = {
  fieldName: string;
  label?: TranslationKey;
  options: (data: Form) => AvailableOrganisationUnbranded[];
  values: Form;
  filterByRoles?: OrganisationRoles[];
};

export function OrganisationDropdown<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
>({
  fieldName,
  label,
  options,
  values,
  filterByRoles,
}: OrganisationDropdownProps<Form, Field>) {
  const theme = useTheme();
  const [fieldInput] =
    useField<OrganisationFormBuilderField<Form, Field>>(fieldName);

  const filteredOptions = filterByRoles
    ? options(values).filter((option) => filterByRoles.includes(option.role))
    : options(values);

  return (
    <Box sx={{ paddingBottom: theme.spacing(3) }}>
      <FormControl fullWidth data-testid={`formField-${fieldName}`}>
        {label && <FormLabel label={label} />}
        <Select
          name={fieldName}
          value={fieldInput.value}
          onChange={fieldInput.onChange}
          onBlur={fieldInput.onBlur}
          data-testid="select-dropdown"
        >
          {filteredOptions.map((option: AvailableOrganisationUnbranded) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
