import React from "react";

import {
  FormControl,
  FormControlLabel,
  Checkbox as MUICheckbox,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";

export type CheckboxProps = {
  fieldName: string;
  label?: TranslationKey;
};

export const Checkbox = ({ fieldName, label }: CheckboxProps) => {
  const { t } = useTranslation();

  const [field, meta] = useField(fieldName);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldName, event.target.checked ? "yes" : "no");
    setFieldTouched(fieldName, true, false);
  };

  return (
    <FormControl error={!!(meta.touched && meta.error)} component="fieldset">
      <FormControlLabel
        control={
          <MUICheckbox
            checked={field.value === "yes"}
            onChange={handleChange}
            name={field.name}
          />
        }
        label={label && t(label)}
      />
      {meta.touched && meta.error && <FormError error={meta.error} />}
    </FormControl>
  );
};
