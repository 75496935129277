import type { UUID } from "@thalamos/common";
import { z, ZodType } from "zod";

export enum AuditTrailEntryResourceType {
  incident = "incident",
  user = "user",
  organisation = "organisation",
}

export type AuditTrailResourceUnbranded = {
  resourceType: AuditTrailEntryResourceType;
  resourceId: UUID;
  resourceName?: string;
};

export const AuditTrailResourceSchema = z.object({
  resourceType: z.nativeEnum(AuditTrailEntryResourceType),
  resourceId: z.string().uuid(),
  resourceName: z.string().optional(),
}) satisfies ZodType<AuditTrailResourceUnbranded>;

export type AuditTrailResource = z.infer<typeof AuditTrailResourceSchema>;

export enum AuditTrailEntryType {
  // Aspire user actions
  aspireReviewIncident = "aspireReviewIncident",
  aspireAcceptIncident = "aspireAcceptIncident",
  aspireRejectIncident = "aspireRejectIncident",

  // Test
  test = "test",

  // Incident
  createIncident = "createIncident",
  getIncident = "getIncident",
  getMyIncidents = "getMyIncidents",

  // User
  userLoginSuccess = "userLoginSuccess",
  userLogout = "userLogout",
  userUpdate = "userUpdate",

  // configuration
  setOrganisationConfiguration = "setOrganisationConfiguration",
  setOrganisationWorkflowVersion = "setOrganisationWorkflowVersion",
}

const BaseAuditTrailEntrySchema = z.object({
  id: z.string().uuid(),
  timestamp: z.coerce.date(),
  userId: z.string().uuid().optional(),
  userName: z.string().optional(),
  organisationId: z.string().uuid().optional(),
  organisationName: z.string().optional(),
  parentTraceId: z.string().optional(),
  sessionId: z.string().optional(),
  codeVersion: z.string(),
  resources: z.array(AuditTrailResourceSchema),
});

export type AuditTrailEntryUnbranded = {
  id: UUID;
  timestamp: Date;

  type: AuditTrailEntryType;
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };

  userId?: UUID;
  userName?: string;

  organisationId?: UUID;
  organisationName?: string;

  parentTraceId?: string;
  sessionId?: string;
  codeVersion: string;

  resources: AuditTrailResourceUnbranded[];
};

export type GetAuditTrailEntriesResponse = {
  entries: AuditTrailEntryUnbranded[];
  count: number;
};

export const AuditTrailEntrySchema = z
  .discriminatedUnion("type", [
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.test),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireAcceptIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid(),
          teamName: z.string(),
          teamEmail: z.string(),
          organisationId: z.string().uuid(),
          organisationName: z.string(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireRejectIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid(),
          teamName: z.string(),
          teamEmail: z.string(),
          organisationId: z.string().uuid(),
          organisationName: z.string(),
          reason: z.string(),
          reasonDescription: z.string().optional(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.aspireReviewIncident),
      data: z
        .object({
          userId: z.string().uuid(),
          userName: z.string(),
          userEmail: z.string(),
          teamId: z.string().uuid().optional(),
          teamName: z.string().optional(),
          teamEmail: z.string().optional(),
          organisationId: z.string().uuid().optional(),
          organisationName: z.string().optional(),
          placeOfSafety: z.object({
            id: z.string().uuid().optional(),
            name: z.string(),
            address: z.object({
              address: z.string(),
              postcode: z.string(),
            }),
            odsCode: z.string().optional(),
          }),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.createIncident),
      data: z
        .object({
          isOnBehalfOfSomeoneElse: z.boolean(),
        })
        .strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.getIncident),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userLoginSuccess),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userLogout),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.getMyIncidents),
      data: z.object({}).strict(),
    }),
    BaseAuditTrailEntrySchema.extend({
      type: z.literal(AuditTrailEntryType.userUpdate),
      data: z
        .object({
          updatedFields: z.array(
            z.enum(["organisationId", "name", "isOnboarded", "shoulderNumber"]),
          ),
        })
        .strict(),
    }),
  ])
  .brand(Symbol("AuditTrailEntry")) satisfies ZodType<AuditTrailEntryUnbranded>;

export type AuditTrailEntry = z.infer<typeof AuditTrailEntrySchema>;

export const AuditTrailSearchParamsSchema = z.object({
  fromDate: z.coerce.date(),
  toDate: z.coerce.date(),
  limit: z.number().min(1).max(1000).optional(),
  offset: z.number().min(0).optional(),
  types: z.array(z.nativeEnum(AuditTrailEntryType)).optional(),
  resourceId: z.string().uuid().optional(),
  userId: z.string().uuid().optional(),
});

export type AuditTrailSearchParams = z.infer<
  typeof AuditTrailSearchParamsSchema
>;
