import { Box, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactNode } from "react";

interface FormWrapperProps {
  children: ReactNode;
}

export const FormWrapper = ({ children }: FormWrapperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const topBottomMargin = isTablet
    ? theme.spacing(15) // 120px
    : !isMobile
      ? theme.spacing(20) // 160px
      : theme.spacing(2); // 16px

  const boxWidth = isMobile ? "100%" : isTablet ? "90%" : theme.spacing(87.5); // 700px

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: `0 ${isMobile ? "8px" : "24px"}`,
        my: topBottomMargin,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          width: boxWidth,
          maxWidth: "90vw",
          padding: isMobile ? theme.spacing(3) : theme.spacing(3, 10),
          boxSizing: "border-box",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            height: "100%",
          }}
        >
          <Box sx={{ flex: 1 }}>{children}</Box>
        </Box>
      </Paper>
    </Box>
  );
};
