import React from "react";

import { Box, Button, DialogActions, useTheme } from "@mui/material";
import { FormBuilderField, TranslationKey } from "@vision/common";
import { Formik } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { TypographyI18N } from "../../index.js";
import { Field } from "../Field.js";

export const SinglePageForm = <Schema extends z.ZodTypeAny>({
  schema,
  initialValues,
  onSubmit,
  content,
  canCancel,
  onCancel,
  confirmButtonLabel,
}: {
  schema: Schema;
  initialValues: z.infer<Schema>;
  onSubmit: (values: z.infer<Schema>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: { fields: FormBuilderField<z.infer<Schema>, any>[] };
  canCancel?: boolean;
  onCancel?: () => void;
  confirmButtonLabel?: TranslationKey;
}) => {
  const theme = useTheme();
  return (
    <Formik
      validationSchema={toFormikValidationSchema(schema)}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <>
            {content.fields.map((field, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom:
                    index === content.fields.length - 1 ? 0 : theme.spacing(3),
                }}
              >
                <Field key={index} field={field} {...formikProps} />
              </Box>
            ))}

            <DialogActions
              sx={{
                ...(canCancel && {
                  display: "flex",
                  justifyContent: "space-between",
                }),
                position: "sticky",
                bottom: 0,
                backgroundColor: theme.palette.common.white,
              }}
            >
              {canCancel && (
                <Button
                  variant="outlined"
                  onClick={onCancel}
                  data-testid="formButton-cancel"
                  color="error"
                >
                  <TypographyI18N
                    translationKey={"singlePageForm.cancel"}
                    sx={{
                      fontSize: theme.spacing(1.75),
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  />
                </Button>
              )}
              <Button
                variant="contained"
                data-testid="formButton-submit"
                onClick={async () => {
                  formikProps.handleSubmit();
                }}
              >
                <TypographyI18N
                  translationKey={
                    confirmButtonLabel ?? "singlePageForm.confirm"
                  }
                  sx={{
                    color: theme.palette.primary.contrastText,
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: theme.spacing(1.75),
                  }}
                />
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
};
