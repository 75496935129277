import { FormLabel as MuiFormLabel } from "@mui/material";
import { TranslationKey } from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";

export const FormLabel = ({ label, ...props }: { label: TranslationKey }) => {
  const { t } = useTranslation();

  return (
    <MuiFormLabel {...props} sx={{ fontWeight: "bold" }}>
      {t(label)}
    </MuiFormLabel>
  );
};
