import type { ActionInput } from "../ActionInput.js";
import { incidentIsActive, userIsIncidentOwner } from "../helpers.js";

type InitiateTransferAction = {
  canInitiate: boolean;
};

type RevokeTransferAction = {
  canRevoke: boolean;
};

type AcceptRejectTransferAction = {
  canAcceptReject: boolean;
};

type TransferActions = InitiateTransferAction &
  RevokeTransferAction &
  AcceptRejectTransferAction;

function canInitiateTransfer(actionInput: ActionInput): boolean {
  return incidentIsActive(actionInput) && userIsIncidentOwner(actionInput);
}

function canRevokeTransfer(actionInput: ActionInput): boolean {
  return incidentIsActive(actionInput) && userIsIncidentOwner(actionInput);
}

function canAcceptRejectTransfer(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    // can't accept/reject if user is the owner
    !userIsIncidentOwner(actionInput)
  );
}

export function calculateTransferActions(
  actionInput: ActionInput,
): TransferActions {
  return {
    canInitiate: canInitiateTransfer(actionInput),
    canRevoke: canRevokeTransfer(actionInput),
    canAcceptReject: canAcceptRejectTransfer(actionInput),
  };
}
