import { thalamosMax01Workflow } from "./thalamosMax0.1Workflow/initialWorkflow.js";
import { thalamosMin01Workflow } from "./thalamosMin0.1Workflow/initialWorkflow.js";
import type { Workflow } from "./Workflow.js";

export const workflows: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Workflow<any, any, any, any, any, any, any>
> = {
  [thalamosMax01Workflow.workflowVersion]: thalamosMax01Workflow,
  [thalamosMin01Workflow.workflowVersion]: thalamosMin01Workflow,
};
