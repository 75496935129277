import { z } from "zod";
import type { OrganisationIdUnbranded } from "./organisation.js";
import {
  WorkflowVersionsEnum,
  type WorkflowVersions,
} from "./workflowVersion.js";

export enum AvailableLanguages {
  English = "en",
  MetropolitanPolice = "enMet",
  BritishTransportPolice = "enBtp",
}

const AvailableLanguagesEnum = z.nativeEnum(AvailableLanguages);

export type OrganisationConfigurationJsonUnbranded = {
  language?: AvailableLanguages;
  associatedEmailDomains?: string[];
  workflowVersion?: WorkflowVersions;
};

const emailDomainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const OrganisationConfigurationJsonSchema = z.object({
  language: AvailableLanguagesEnum.optional(),
  associatedEmailDomains: z
    .array(
      z
        .string()
        .regex(emailDomainRegex, {
          message: "Invalid email domain",
        })
        .max(255),
    )
    .optional(),
  workflowVersion: WorkflowVersionsEnum.optional(),
});

export type OrganisationConfigurationJson = z.infer<
  typeof OrganisationConfigurationJsonSchema
>;

export const OrganisationConfigurationSchema = z.object({
  organisationId: z.string().uuid(),
  configuration: OrganisationConfigurationJsonSchema,
});

export type OrganisationConfiguration = z.infer<
  typeof OrganisationConfigurationSchema
>;

export type OrganisationConfigurationHierarchyUnbranded = {
  organisationId: OrganisationIdUnbranded;
  managingOrganisationId: OrganisationIdUnbranded | null;
  name: string;
  configuration: Partial<OrganisationConfigurationJsonUnbranded>;
}[];

export const OrganisationConfigurationHierarchySchema = z.array(
  z.object({
    organisationId: z.string().uuid(),
    managingOrganisationId: z.string().uuid().nullable(),
    name: z.string().max(255),
    configuration: OrganisationConfigurationJsonSchema.optional(),
  }),
);

export type OrganisationConfigurationHierarchy = z.infer<
  typeof OrganisationConfigurationHierarchySchema
>;
