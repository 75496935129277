import type { ActionInput } from "../ActionInput.js";
import { incidentIsActive, userIsIncidentOwner } from "../helpers.js";

type EndIncidentAction = {
  canEnd: boolean;
};

function canEndIncident(actionInput: ActionInput): boolean {
  return incidentIsActive(actionInput) && userIsIncidentOwner(actionInput);
}

export function calculateEndIncidentActions(
  actionInput: ActionInput,
): EndIncidentAction {
  return {
    canEnd: canEndIncident(actionInput),
  };
}
