import type { UUID } from "@thalamos/common";
import z, { ZodType } from "zod";
import {
  OrganisationConfigurationJsonSchema,
  type OrganisationConfigurationJsonUnbranded,
} from "./configuration.js";
import { EmailSchema } from "./fields.js";
import {
  OrganisationIdSchema,
  UserOrganisationMembershipSchema,
  type OrganisationIdUnbranded,
  type UserOrganisationMembershipUnbranded,
} from "./organisation.js";

/**
 * An ID for a User
 */
export type UserIdUnbranded = UUID;
export const UserIdSchema = z
  .string()
  .uuid()
  .brand(Symbol("UserId")) satisfies ZodType<UserIdUnbranded>;
export type UserId = z.infer<typeof UserIdSchema>;

/**
 * A User
 */
export type UserUnbranded = {
  id: UserIdUnbranded;
  name: string;
  email: string;
  shoulderNumber?: string;
};

export const UserSchema = z
  .object({
    id: UserIdSchema,
    name: z.string().min(1).max(255),
    email: EmailSchema,
    shoulderNumber: z.string().max(255).optional(),
  })
  .brand(Symbol("User")) satisfies ZodType<UserUnbranded>;

export type User = z.infer<typeof UserSchema>;

export type ExtendedVisionUserUnbranded = UserUnbranded & {
  memberships: UserOrganisationMembershipUnbranded[];
  sessionContext: UserOrganisationMembershipUnbranded | null;
  sessionOrganisationConfiguration?: OrganisationConfigurationJsonUnbranded;
  isOnboarded: boolean;
};

export const ExtendedVisionUserSchema = z
  .object({
    id: UserIdSchema,
    name: z.string().min(1).max(255),
    email: EmailSchema,
    shoulderNumber: z.string().max(255).optional(),
    isAdminSession: z.boolean(),
    sessionContext: UserOrganisationMembershipSchema.nullable(),
    sessionOrganisationConfiguration:
      OrganisationConfigurationJsonSchema.optional(),
    memberships: z.array(UserOrganisationMembershipSchema),
    isOnboarded: z.boolean(),
  })
  .brand(
    Symbol("ExtendedVisionUser"),
  ) satisfies ZodType<ExtendedVisionUserUnbranded>;

export type ExtendedVisionUser = z.infer<typeof ExtendedVisionUserSchema>;

export type CreateUserUnbranded = UserUnbranded & {
  organisationId: OrganisationIdUnbranded;
};

export const CreateUserSchema = z
  .object({
    id: UserIdSchema,
    name: z.string().min(1).max(255),
    email: EmailSchema,
    shoulderNumber: z.string().max(255).optional(),
    organisationId: OrganisationIdSchema,
  })
  .brand(Symbol("CreateUser")) satisfies ZodType<CreateUserUnbranded>;

export type CreateUser = z.infer<typeof CreateUserSchema>;

export type UserUpdateRequestUnbranded = {
  organisationId?: OrganisationIdUnbranded;
  name?: string;
  isOnboarded?: boolean;
  shoulderNumber?: string;
};

export const UserUpdateRequestSchema = z.object({
  organisationId: OrganisationIdSchema.optional(),
  name: z.string().optional(),
  isOnboarded: z.boolean().optional(),
  shoulderNumber: z.string().optional(),
});

export type UserUpdateRequest = z.infer<typeof UserUpdateRequestSchema>;
