import { z, ZodType } from "zod";
import type { FormSection } from "../workflowDefinitions/Workflow.js";

type FormSectionCompletePosUnbranded = {
  pos: string;
  arrivalDate: string;
};

const _FormSectionCompletePosSchema = z.object({
  pos: z.string(),
  arrivalDate: z.string(),
}) satisfies ZodType<FormSectionCompletePosUnbranded>;

export type FormSectionCompletePos = z.infer<
  typeof _FormSectionCompletePosSchema
>;

type FormSectionCompleteIncidentMainUnbranded = {
  officer: string;
};

const _FormSectionCompleteIncidentMainSchema = z.object({
  officer: z.string(),
}) satisfies ZodType<FormSectionCompleteIncidentMainUnbranded>;

export type FormSectionCompleteIncidentMain = z.infer<
  typeof _FormSectionCompleteIncidentMainSchema
>;

export type FormSectionCompleteProps = {
  type: FormSection;
  payload?: FormSectionCompletePos | FormSectionCompleteIncidentMain;
};
