import { Box, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField as MUITimeField } from "@mui/x-date-pickers/TimeField";
import { TranslationKey } from "@vision/common";
import dayjs, { Dayjs } from "dayjs";
import { useFormikContext } from "formik";
import React from "react";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

interface FormValues {
  [key: string]: unknown;
}

export type CombinedDateTimePickerOption = {
  label?: TranslationKey;
  fieldName: string;
  dateLabel: TranslationKey;
  timeLabel: TranslationKey;
};

export const combineDateAndTime = (
  date: string | null,
  time: string | null,
): dayjs.Dayjs | null => {
  if (!date || !time) return null;

  const validDate = dayjs(date);
  const validTime = dayjs(time, "HH:mm", true);

  if (validDate.isValid() && validTime.isValid()) {
    return validDate.hour(validTime.hour()).minute(validTime.minute());
  }

  return null;
};

export const DateTimePicker = ({
  label,
  fieldName,
  dateLabel,
  timeLabel,
}: CombinedDateTimePickerOption) => {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<FormValues>();
  const [date, setDate] = React.useState<string | null>(null);
  const [time, setTime] = React.useState<string | null>(null);

  React.useEffect(() => {
    const combinedDateTime = combineDateAndTime(date, time);

    if (combinedDateTime) {
      setFieldValue(fieldName, combinedDateTime.toISOString());
    } else {
      setFieldValue(fieldName, "");
    }
  }, [date, fieldName, setFieldValue, time, values]);

  return (
    <>
      {label && <FormLabel label={label} />}
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={4}
      >
        <Box sx={{ flex: 1 }}>
          <DatePickerInput
            date={date}
            setDate={setDate}
            label={dateLabel}
            fieldName={fieldName}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <TimeField
            time={time}
            setTime={setTime}
            label={timeLabel}
            fieldName={fieldName}
          />
        </Box>
      </Stack>
      {errors[fieldName] && touched[fieldName] && (
        <FormError data-testid="time-date-error" error={errors[fieldName]} />
      )}
    </>
  );
};

export const TimeField = ({
  time,
  setTime,
  label,
  fieldName,
}: {
  time: string | null;
  setTime: React.Dispatch<React.SetStateAction<string | null>>;
  label: TranslationKey;
  fieldName: string;
}) => {
  const handleTimeChange = (value: Dayjs | null) => {
    if (value === null) {
      setTime("");
    } else if (value.isValid()) {
      setTime(value.format("HH:mm"));
    }
  };

  return (
    <>
      <FormLabel label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUITimeField<Dayjs>
          value={time && dayjs(time).isValid() ? dayjs(time) : null}
          onChange={handleTimeChange}
          ampm={false}
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}-time`,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

const DatePickerInput = ({
  date,
  setDate,
  label,
  fieldName,
}: {
  date: string | null;
  setDate: React.Dispatch<React.SetStateAction<string | null>>;
  label: TranslationKey;
  fieldName: string;
}) => {
  const handleDateChange = (value: Dayjs | null) => {
    if (value === null) {
      setDate("");
    } else if (value.isValid()) {
      setDate(value.format("YYYY-MM-DD"));
    }
  };

  return (
    <>
      <FormLabel label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker<Dayjs>
          value={date ? dayjs(date, "YYYY-MM-DD") : null}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}-date`,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};
