export default {
  unknown: "Unknown",
  fieldSchema: {
    dateTime: {
      invalid: "Invalid date and time",
    },
    date: {
      invalid: "Invalid date",
    },
    time: {
      invalid: "Invalid time",
    },
    dispatchNumber: {
      tooShort: "$t(common.dispatchNumber) must be at least 4 characters",
    },
  },
  role: {
    officer: "Officer",
    commandunit: "Command Unit",
    supervisor: "Supervisor",
    clientAdmin: "Client Admin",
    analyticsViewer: "Analytics Viewer",
    guestUser: "Guest User",
  },
  navigation: {
    home: "Home",
    newIncident: "New Incident",
    inProgressIncidents: "In-progress Incidents",
    endedIncidents: "Ended Incidents",
    help: "Help",
    notifications: "Notifications",
    logout: "Logout",
  },
  login: {
    loginToAccess: "Click login to access Vision portal",
    login: "Login",
  },
  common: {
    dispatchNumber: "Dispatch Number",
    yes: "Yes",
    no: "No",
    other: "Other",
    close: "Close",
  },
  multiPageForm: {
    next: "Next",
    cancel: "Cancel",
    storybook: {
      wouldYouLikeToPlayAGame:
        "Would you like to play a game? (yes/no) [This is a branching question]",
      whatKindOfGame:
        "What kind of game do you want to play? (number/word) [This reveals different fields on a page]",
      numberGameLowNumber: "Pick a number, any number.",
      numberGameHighNumber:
        "Pick a number, higher than the previous number. [This shows on-page validation between fields]",
      wordGamePhrase: "What is a word you struggle to spell?",
      whyDontYouWantToPlayAGame: "Why don't you want to play a game?",
      didYouEnjoyTheGame:
        "Did you enjoy the game? [This page only appears if you played the game]",
      willYouConsiderPlayingAnotherTime:
        "Will you consider playing again another time? (yes/no) [We converge here irrespective of the branch taken at the start]",
    },
  },
  incidentBanner: {
    viewShareForm: "Share Form",
    viewTimeline: "View Timeline",
    personName: "Name of Person",
    incidentStatus: "Incident Status",
  },
  incidentWorkItem: {
    startNew: "Start new incident form",
    section136: "Section 136",
    section56: "Section 5-6",
    voluntary: "Voluntary",
    newForm: "New Form",
    nameOfPerson: "Name of person",
    typeOfIncident: "Type of incident",
    createdAt: "Created at",
    commandUnit: "Command Unit",
    currentAssignee: "Officer Assigned",
    view: "View",
    formInProgress: "Form in progress",
    formStatusUnknown: "Form status unknown",
    unknownOwner: "Unknown",
    type: {
      "136": "Section 136",
    },
    myIncidents: "My Incidents",
    transferredIncidents: "Transferred Incidents",
    yourIncidentsHere: "Your incidents will show here",
    transferredIncidentsHere: "Transferred Incidents will show here",
  },

  createIncident: {
    newIncidentForm: "New Incident Form",
    dispatchNumberCreatedDate: "Date of $t(common.dispatchNumber) creation",
    dispatchNumberCreatedTime: "Time of $t(common.dispatchNumber) creation",
    personForename: "Person's Forename",
    personSurname: "Person's Surname",
    commandUnit:
      "In which $t(incidentWorkItem.commandUnit) did the incident happen?",
  },
  incidentFormSections: {
    incident: "Incident created",
    triage: "Triage",
    incidentAndTriage: "Incident created + Triage",
    detention: "Detention details",
    person: "Person details",
    information: "Information for Healthcare",
  },
  formProgressPage: {
    handover: "Handover",
    handoverAndShare: "Share & Handover",
    arrival: "Arrival",
    activePlaceOfSafety: "Active Place of safety: ",
    confirmArrival:
      "Confirm arrival when you enter the doors of the place of safety",
    confirmHandover:
      "Confirming handover will share 136 details with place of safety",
    endIncident: "End Incident",
    transfer: "Transfer",
    add: "Add +",
    view: "View",
    s136InProgress: "S136 - Form in progress",
  },
  onboarding: {
    welcomeTitle: "Welcome to Thalamos!",
    welcomeMessage:
      "As this is your first time logging on, you will need to provide some details about yourself before completing any forms. Please fill out the questions below.",
    name: "Name:",
    email: "Email:",
    organisationMembership: "Organisation Membership:",
    confirm: "Confirm",
    error: "Failed to save details",
  },
  triageIncident: {
    title: "Incident Triage",
    isHealthcareProfessionalConsulted:
      "Have you consulted a healthcare professional to confirm the course of action needed?",

    howDidYouContactNhs: {
      title: "How did you contact the healthcare professional?",
      options: {
        healthcarePractitionerAmbulance:
          "Healthcare practitioner/Ambulance on scene",
        ldPractitionersInCustody: "L&D practitioners in custody",
        custodyHealthcarePractitioner:
          "Custody Healthcare practitioner (Dr/RN/Para)",
        panLondonMentalHealthAdviceLine:
          "Pan London Mental Health Advice Line: 0300 200 0169",
        localMentalHealthAdviceLine:
          "Direct to local mental health advice line",
        localHbpos: "Direct to local HBPOS (Health-Based Place of Safety)",
        localStreetTriageTeam: "Direct to local street triage team",
        other: "Other",
      },
    },
    isAdviceGiven:
      "Please confirm: Healthcare professional is of the opinion that the person is likely to lack the mental capacity to make this decision as a result of a mental disorder",
    givenHealthCareProfessionalAdvice:
      "What advice did the healthcare professional give?",
    reasonsForDetention: "Please provide reasons for detention",
    clinicalCaseRecordNumber:
      "Please provide the clinical case record number for this call",
    provideDetails: "Please provide details",
    confirmMentalCapacityLack:
      "Please confirm: Healthcare professional is of the opinion that the person is likely to lack the mental capacity to make this decision as a result of a mental disorder",
    reasonNotConsulted: {
      title: "Why was a healthcare professional not consulted?",
      options: {
        needForCare: "Immediate need for care (Not practicable)",
        immediateControl: "Immediate need for control (Not practicable)",
        noAnswer: "No answer/reply",
      },
    },
    whatActionWillYouTake: "What action will you take?",
    voluntaryAction: {
      options: {
        voluntaryAttendanceHospital: "Voluntary attendance at hospital",
        divertOtherHealthservice: "Divert to other health service",
        contactSupportGroup: "Contact support group",
        contactFriendsFamily: "Contact friends or family",
        takePersonHome: "Take person home",
        divertCrisisCafe: "Divert to crisis cafe",
        other: "None of the above (please provide details)",
      },
    },
    otherAction: {
      title: "What other action will you take?",
      options: {
        arrestCriminalOffence: "Arrest for criminal offence",
        watchList: "Put on watch list",
        referSupportOptions: "Refer to other support options",
        other: "Other (please specify)",
      },
    },
    whatWillYouDo: {
      136: "136",
      56: "5-6",
      voluntary: "Voluntary",
      other: "Other",
    },
  },
  recordPlaceOfSafety: {
    title: "Record Place of Safety",
    choosePlaceOfSafety: {
      title: "Choose a Place of Safety",
      confirmCorrect: "Please confirm these details are correct",
    },
    choosePlaceOfSafetyType: {
      title: "What type of Place of Safety is this?",
      options: {
        hbPos136: "HBPOS (136 Suite)",
        emergencyDepartment: "Emergency Department (A&E)",
        returnedToMentalHealthUnit: "Returned to Mental Health Unit",
        careHome: "Care Home",
        custody: "Custody (exceptional circumstances)",
        privateHome: "Private home",
        other: "Other (please specify)",
      },
    },
    choosePlaceOfSafetyTypeOther: {
      title: "Please specify",
    },
    whyNotHbPosReason: {
      title:
        "Why are you not taking this person to a Health Based Place of Safety?",
      options: {
        noHbPosAvailable:
          "Advised that no Health Based Place of Safety (HBPOS) is available",
        unreasonableDelay: "Unreasonable delay in finding a HBPOS",
        medicalAttentionNeeded: "Medical attention needed",
        secureCustodyRequired: "Secure custody environment required",
        other: "Other (please specify)",
      },
    },
    whyNotHbPosReasonOther: {
      title: "Please specify",
    },
  },
  arrivePlaceOfSafety: {
    title: "Arrive at Place of Safety",
    arrivalDate: "What date did you arrive?",
    arrivalTime: "What time did you arrive?",
    wasEntryDelayed: "Was your entry to the Place of Safety delayed?",
    whyWasEntryDelayed: "Why was it delayed?",
    howLongWasEntryDelayed: {
      title: "How long was the entry delayed?",
      options: {
        "0-15": "0-15 minutes",
        "15-30": "15-30 minutes",
        "30-60": "30-60 minutes",
        "60+": "60+ minutes",
      },
    },
    methodOfTransport: {
      title: "How did you travel to the Place of Safety?",
      options: {
        alreadyAtPos: "Already at the Place of Safety",
        ambulance: "Ambulance",
        policeVehicle: "Police vehicle",
        otherHealthVehicle: "Other Health Vehicle",
        escorted: "Escorted (walked)",
        other: "Other (please specify)",
      },
    },
    methodOfTransportOther: {
      title: "Please specify",
    },
    whyMethodOfTransportPoliceVehicle: {
      title: "Why did you use a police vehicle?",
      options: {
        ambulanceAvailableInTimeframe:
          "Ambulance not agreed within agreed timeframe",
        ambulanceCrewRefused: "Ambulance crew refused to convey",
        riskAssessment: "Police or police/ambulance risk assessment",
        ambulanceNotRequested: "Ambulance not requested",
        ambulanceRetasked: "Ambulance retasked to a higher priority call",
        other: "Other (please specify)",
      },
    },
    whyMethodOfTransportPoliceVehicleOther: {
      title: "Please specify",
    },
  },
  singlePageForm: {
    cancel: "Close",
    confirm: "Confirm",
  },
  userProfile: {
    title: "Profile",
    banner:
      "Please enter your professional information and click save. Your profile details will be populated onto the forms you are completing.",
    confirm: "Save",
    name: "Name",
    email: "Email",
    organisationMembership: "Organisation Membership",
    role: "Role",
    shoulderNumber: "Shoulder Number",
    success: "Profile successfully saved",
    error: "Failed to save profile",
  },
  formSuccess: {
    proceed: "Proceed",
    formComplete: "Form section complete",
    proceedWith: "Proceed with:",
    mentalHealthFormTitle: "Mental Health Incident form",
    mentalHealthForm: "Mental health incident form created by officer ",
    incidentTriage: "Incident Triage",
    triageComplete: "Incident Triage completed Section 136 form started.",
    personDetails: "Person details",
    personDetailsComplete: "Person Detail section has been completed",
    detentionDetails: "Detention details",
    detentionDetailsComplete: "Detention Detail section has been completed",
    informationForHealthcare: "Information for healthcare",
    informationForHealthcareStaff: "Information for healthcare staff",
    informationForHealthcareComplete:
      "Information for healthcare section has been completed",
    sharePOS: "Share place of Safety",
    addPOS: "Add place of safety",
    arrivePOS: "Arrive at place of safety",
    confirmed: "Confirmed",
    proceedToShare:
      "Proceed to share details with Place of Safety and initiate handover",
    currentPos: "Current place of safety",
    arrivedAt: "Arrived at",
  },
  personDetails: {
    title: "Person Details",
    homeAddress:
      "Please provide and confirm the person's home address or select unknown",
    localAuthorityAddress: "Local authority of person's home address",
    relativeOrFriendAddress: "Address of relative or friend",
    relativeOrFriendTelephone: "Telephone number of relative or friend",
    relativeOrFriendInformed:
      "Has the relative or friend been informed of the detention?",
    reasonNotInformed:
      "Please explain why the relative or friend has not been informed",
    gender: {
      title: "Gender",
      options: {
        male: "Male",
        female: "Female",
        nonBinary: "Non-binary",
        preferNotToSay: "Prefer not to say",
      },
    },
    ethnicity: {
      title: "Ethnic Appearance Code",
      options: {
        IC1: "IC1 - White, Northern European",
        IC2: "IC2 - Mediterranean/Southern European/Hispanic",
        IC3: "IC3 - African/Afro-Caribbean",
        IC4: "IC4 - Indian, Pakistani, Nepalese, Maldivian, Sri Lankan, Bangladeshi, or any other (South) Asian",
        IC5: "IC5 - Chinese, Japanese, or South-East Asian",
        IC6: "IC6 - Middle Eastern/Arab",
        IC7: "IC7 - Origin UNKNOWN",
      },
    },
    selfDeclaredEthnicity: {
      title: "Self Declared Ethnicity",
      options: {
        whiteEnglish:
          "White – English / Welsh / Scottish / Northern Irish / British",
        whiteIrish: "White – Irish",
        whiteGypsy: "White – Gypsy or Irish Traveller",
        whiteRoma: "White – Roma",
        whiteOther: "Any other White background, please describe",
        mixedBlackCaribbean:
          "Mixed / Multiple ethnic groups – White and Black Caribbean",
        mixedBlackAfrican:
          "Mixed / Multiple ethnic groups – White and Black African",
        mixedAsian: "Mixed / Multiple ethnic groups – White and Asian",
        mixedOther:
          "Any other Mixed / Multiple ethnic background, please describe",
        asianEnglish:
          "Asian / Asian British - English / Welsh / Scottish / Northern Irish / British",
        asianIndian: "Asian / Asian British - Indian",
        asianPakistani: "Asian / Asian British - Pakistani",
        asianBangladeshi: "Asian / Asian British - Bangladeshi",
        asianChinese: "Asian / Asian British – Chinese",
        asianOther: "Any other Asian background, please describe",
        blackEnglish:
          "Black / African / Caribbean / Black British – English / Welsh / Scottish / Northern Irish / British",
        blackAfrican: "Black / African / Caribbean / Black British - African",
        blackCaribbean:
          "Black / African / Caribbean / Black British – Caribbean",
        blackOther:
          "Black / African / Caribbean / Black British – Any other Black / African / Caribbean background, please describe",
        arab: "Other ethnic group - Arab",
        other: "Any other ethnic group, please describe",
        preferNotToSay: "Prefer not to say",
      },
    },
    dob: "Person's date of birth",
    nationalComputerCheck:
      "Have you carried out a Police National Computer check?",
    pncRecordForPerson:
      "Is there a Police National Computer record for this person?",
    pncidNumber: "PNCID no.",
    pncWarningMarkers: {
      title: "What are the PNC Warning markers?",
      options: {
        alleges: "AG - Alleges",
        ailment: "AT - Ailment",
        conceals: "CL - Conceals",
        contagious: "CO - Contagious",
        drugs: "DR - Drugs",
        escaper: "ES - Escaper",
        firearm: "FI - Firearm",
        fImpers: "IF - F/Impers",
        mImpers: "IM - M/Impers",
        impersonates: "IS - Impersonates",
        mentalHealth: "MN - Mental Health",
        selfHarm: "SH - Self Harm",
        suicidal: "SU - Suicidal",
        violent: "VI - Violent",
        weapons: "WE - Weapons",
        explosives: "XP - Explosives",
      },
    },
    relativeOrFriend: "Do you have the details of a relative or friend?",
    relativeOrFriendForename: "Relative or friend forename(s)",
    relativeOrFriendSurname: "Relative or friend surname",
    relativeOrFriendRelationship: "Relationship to the person being detained",
    relativeOrFriendSameAddress:
      "Is this the home address of the relative or friend the same as the person being detained?",
    unknownRelativeOrFriend:
      "Details of a relative or friend will be recorded as 'unknown'",
    unknownRelativeOrFriendAddress:
      "Home address of a relative or friend will be recorded as 'unknown'",
  },
  detentionDetails: {
    title: "Detention Details",
    custodyNumber: "Custody Number",
    dateOfDetention: "Date of s136 detention",
    timeOfDetention: "Time of s136 detention",
    responsibleForDetention: "BCU responsible for detention",
    detentionTookPlace: "Location where detention took place",
    wasLifesavingInterventionDetails:
      "Please confirm in line with Ovenstone Criteria, including direct or indirect evidence of suicidal intent",
    intervention: "Who made the Intervention?",
    locationType: {
      title: "What type of location was this",
      railwayLines: "Railway lines",
      hospitalWards: "Hospital wards",
      rooftopsCommercial: "Rooftops (of commercial or business buildings)",
      policeStations: "Police stations",
      offices: "Offices",
      school: "School",
      gardensCarParks:
        "Gardens and car parks associated with communal residential property",
      nonResidentialRestricted:
        "Non-residential parts of residential buildings with restricted entry",
      other: "Other",
    },
    wasLifesavingIntervention: "Was this a lifesaving intervention (LS)?",
  },
  informationForHealthcare: {
    title: "Information for Healthcare",
    incidentDescription:
      "Please give an account of the behaviour or crisis that led to Section 136 (MHA) powers being used",
    wasHealthcareProvided:
      "Was physical health care provided prior to arrival at the Place of Safety (ED or HBPOS)?",
    medicalAttentionProvided: "Please describe the medical attention provided",
    wasForceUsed: "Was force used during detention?",
    describeForceUsed: "Please describe the force used, and for how long",
    hasPersonBeenSearched: "Has the person been searched?",
    didSeizeproperty: "Did you seize any property",
    detailsPropertySeized: "Detail of the property seized",
    underInfluenceOfSubstance:
      "Is the person under the influence of alcohol or drugs?",
    hasAdditionalRiskFactors:
      "Are there any additional risk factors that should be shared with healthcare staff?",
    describeRiskFactors: "Please describe the additional risk factors",
  },
  placeOfSafetyPicker: {
    manuallyEnter: "Manually enter",
    name: "Name",
    address: "Address",
    postalCode: "Postal Code",
  },

  transfer: {
    initiateTransfer: {
      title: "Transfer to another officer?",
      confirmedTransfer:
        "Once you have confirmed the transfer, the receiving officer must accept the form for the transfer to complete.",
      untilTransferAccepted:
        "Until the transfer is accepted, the form will remain your responsibility.",
      enterEmailAddress: "Enter email address of receiving officer",
      confirm: "Confirm Transfer",
      confirmToast: "Incident transferred to {{ email }}",
    },
  },
};
