import {
  OrganisationConfigurationSchema,
  OrganisationRoles,
  OrganisationSchema,
  WorkflowVersions,
} from "../../types/index.js";

const thalamosOrgId = "a4157eff-87da-4799-831d-755006e3e81d";
const rootOrgId = "b3046fee-87da-4799-831d-755006e3e81d";
const parentOrgId = "cbb8c1d8-ce1b-4e30-995c-00a0304a6160";
const childOrgId = "0378dfeb-c15c-4788-9be3-ac1c781ba118";

const btpRootOrgId = "2a26d5b3-7161-4f9a-9625-2c6edfa49b0a";
const btpRegionalOrgAId = "ae3a9ec2-f3fb-4f03-a5f8-25af02f86760";
const btpRegionalOrgBId = "0623cc7c-aa44-47b7-8b3c-30101b9f507a";
const btpChildOrgId = "5d50780e-e358-44f1-aac3-a4ce3c9c0f95";

const metRootOrgId = "b1b905ab-74b0-4452-8da1-0f0a5a10e306";

export const thalamosOrg = OrganisationSchema.parse({
  id: thalamosOrgId,
  name: "Thalamos",
  address: "Old Union Street Arches",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.Admin,
  status: "active",
});

export const rootOrg = OrganisationSchema.parse({
  id: rootOrgId,
  name: "A Division",
  address: "25 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

export const parentOrg = OrganisationSchema.parse({
  id: parentOrgId,
  name: "B Division",
  address: "26 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: rootOrgId,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

export const childOrg = OrganisationSchema.parse({
  id: childOrgId,
  name: "C Division",
  address: "27 Camden Road London",
  postalCode: "NW1 9LN",
  managingOrganisationId: parentOrgId,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

const btpRootOrg = OrganisationSchema.parse({
  id: btpRootOrgId,
  name: "British Transport Police",
  address: "10 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

const btpRegionalOrgA = OrganisationSchema.parse({
  id: btpRegionalOrgAId,
  name: "Force Headquarters",
  address: "11 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpRootOrg.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

const btpRegionalOrgB = OrganisationSchema.parse({
  id: btpRegionalOrgBId,
  name: "London and South East",
  address: "12 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpRootOrg.id,
  role: OrganisationRoles.PoliceGeographicUnit,
  status: "active",
});

export const btpChildOrg = OrganisationSchema.parse({
  id: btpChildOrgId,
  name: "London and South East",
  address: "13 Oxford Street London",
  postalCode: "NW11 9PP",
  managingOrganisationId: btpRegionalOrgA.id,
  role: OrganisationRoles.PoliceCommandUnit,
  status: "active",
});

const metOrg = OrganisationSchema.parse({
  id: metRootOrgId,
  name: "Metropolitan Police",
  address: "100 Camden Road London",
  postalCode: "TE1 9LN",
  managingOrganisationId: null,
  role: OrganisationRoles.PoliceForce,
  status: "active",
});

const rootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: rootOrgId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["vision.thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const btpRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: btpRootOrgId,
  configuration: {
    language: "enBtp",
    associatedEmailDomains: ["btp.police.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const metRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: metRootOrgId,
  configuration: {
    language: "enMet",
    associatedEmailDomains: ["met.police.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

const thalamosRootOrgConfiguration = OrganisationConfigurationSchema.parse({
  organisationId: thalamosOrgId,
  configuration: {
    language: "en",
    associatedEmailDomains: ["thalamos.co.uk"],
    workflowVersion: WorkflowVersions.ThalamosMax01,
  },
});

export const organisations = [
  rootOrg,
  parentOrg,
  childOrg,
  btpRootOrg,
  btpRegionalOrgA,
  btpRegionalOrgB,
  btpChildOrg,
  metOrg,
  thalamosOrg,
];

export const organisationConfigurations = [
  rootOrgConfiguration,
  btpRootOrgConfiguration,
  metRootOrgConfiguration,
  thalamosRootOrgConfiguration,
];
