export default {
  role: {
    officer: "Met Officer",
    commandunit: "Basic Command Unit",
  },
  incidentWorkItem: {
    commandUnit: "BCU",
  },
  common: {
    dispatchNumber: "CAD Number",
  },
  userProfile: {
    shoulderNumber: "Warrant Number",
  },
};
