import {
  IncidentFormId,
  IncidentWorkItemId,
  IncidentWorkItemIdUnbranded,
  UserIdUnbranded,
} from "@vision/common";

export const createRoute = {
  help: () => "/help",
  home: () => "/",
  incidentActive: () => "/incident/active",
  incidentArchive: () => "/incident/archive",
  incidentCreate: () => "/incident/create",
  incidentProgressPage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/progress`,
  incidentTriagePage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/triage`,
  incidentSectionComplete: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/section-complete`,
  incidentPersonDetailsPage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/person-details`,
  incidentDetentionDetailsPage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/detention-details`,
  incidentInformationForHealthcarePage: (
    incidentId: IncidentWorkItemIdUnbranded,
  ) => `/incident/${incidentId}/information-for-healthcare`,
  incidentRecordPlaceOfSafetyPage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/place-of-safety/create`,
  incidentRecordPlaceOfSafetyArrivalPage: (
    incidentId: IncidentWorkItemId,
    placeOfSafetyFormId: IncidentFormId,
  ) => `/incident/${incidentId}/place-of-safety/${placeOfSafetyFormId}/arrival`,
  notifications: () => "/notifications",
  login: () => "/login",
  userProfile: (id: UserIdUnbranded) => `/profile/${id}`,
};
