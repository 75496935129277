import React from "react";

import {
  FormSection,
  IncidentType,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import api from "../../api/index.js";
import {
  TriageIncidentForm,
  TriageIncidentFormSkeleton,
} from "../../forms/TriageIncident/TriageIncidentForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type TriageIncidentPageProps = Record<string, unknown>;

export function TriageIncidentPage(_props: TriageIncidentPageProps) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const triageForm = incidentWorkItem?.forms?.find(
      (form) => form.formType === "triageIncident",
    );
    const triageFormId = triageForm?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;
    return (
      <>
        {incidentWorkItemId === undefined ||
        triageFormId === undefined ||
        workflowVersion === undefined ? (
          <TriageIncidentFormSkeleton />
        ) : (
          <TriageIncidentForm
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialValues={triageForm?.sections?.[0]?.data as any}
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }

                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  triageFormId,
                  FormSection.triageMain,
                  values,
                  true,
                );
                if (response.status === 204) {
                  if (values.whatWillYouDo.value === IncidentType.section136) {
                    const res =
                      await api.incidentWorkItem.create136Form(
                        incidentWorkItemId,
                      );

                    if (res.status !== 204) {
                      setError(JSON.stringify(res.data.message));
                      return;
                    }
                  }
                  revalidator.revalidate();
                  navigate(`/incident/${incidentWorkItemId}/section-complete`, {
                    state: {
                      type: FormSection.triageMain,
                    },
                  });
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to create incident work item: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() =>
              navigate(createRoute.incidentProgressPage(incidentWorkItemId))
            }
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
