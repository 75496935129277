import React from "react";

import {
  FormSection,
  IncidentFormIdSchema,
  IncidentWorkItemWithContext,
  WorkflowVersions,
} from "@vision/common";
import {
  Await,
  useNavigate,
  useParams,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import api from "../../api/index.js";
import {
  ArrivePlaceOfSafetyForm,
  ArrivePlaceOfSafetyFormSkeleton,
} from "../../forms/ArrivePlaceOfSafety/ArrivePlaceOfSafetyForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type ArrivePlaceOfSafetyPageProps = Record<string, unknown>;

export function ArrivePlaceOfSafetyPage(_values: ArrivePlaceOfSafetyPageProps) {
  const navigate = useNavigate();
  const params = useParams();
  const revalidator = useRevalidator();

  const placeOfSafetyFormId = IncidentFormIdSchema.safeParse(params.formId);
  if (placeOfSafetyFormId.success === false) {
    throw new Error("Invalid form ID");
  }

  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;

  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const workflowVersion = incidentWorkItem?.incidentWorkItem
      ?.workflowVersion as WorkflowVersions | undefined;
    return (
      <>
        {incidentWorkItemId === undefined || workflowVersion === undefined ? (
          <ArrivePlaceOfSafetyFormSkeleton />
        ) : (
          <ArrivePlaceOfSafetyForm
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }

                // Populate it with values
                const populateFormResponse =
                  await api.incidentWorkItem.updateData(
                    incidentWorkItemId,
                    placeOfSafetyFormId.data,
                    FormSection.recordPlaceOfSafetyArrival,
                    values,
                    true,
                  );
                if (populateFormResponse.status !== 204) {
                  throw Error(
                    JSON.stringify(populateFormResponse.data.message),
                  );
                }

                revalidator.revalidate();
                navigate(createRoute.incidentProgressPage(incidentWorkItemId));
                return;
              } catch (error) {
                setError(
                  "Failed to record arrival at place of safety: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() =>
              navigate(createRoute.incidentProgressPage(incidentWorkItemId))
            }
            workflowVersion={workflowVersion}
          />
        )}
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
