import { IncidentType } from "../../types/incidentType.js";
import { IncidentFormType } from "../../types/workItem.js";
import {
  FormSection,
  type TriageIncidentMinimumFields,
} from "../../workflowDefinitions/Workflow.js";
import type { ActionInput } from "../ActionInput.js";
import {
  hasSection136Form,
  hasTriageForm,
  incidentIsActive,
  userIsIncidentOwner,
} from "../helpers.js";

type StartSection136Action = {
  canStart: boolean;
};

type EditSection136Action = {
  canEdit: boolean;
};

type Section136Actions = StartSection136Action & EditSection136Action;

function triageSelectionIsSection136({
  incidentWorkItem,
}: ActionInput): boolean {
  const triageForm = incidentWorkItem.forms.find(
    (form) => form.formType === IncidentFormType.triage,
  );

  if (!triageForm || !triageForm.sections) return false;

  const triageMainSection = triageForm.sections.find((section) => {
    return section.id === FormSection.triageMain;
  });

  if (!triageMainSection || !triageMainSection.data) return false;

  return (
    (triageMainSection.data as TriageIncidentMinimumFields).whatWillYouDo
      ?.value === IncidentType.section136
  );
}

function canStartSection136(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput) &&
    triageSelectionIsSection136(actionInput)
  );
}

function canEditSection136(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput) &&
    hasSection136Form(actionInput)
  );
}

export function calculateSection136Actions(
  actionInput: ActionInput,
): Section136Actions {
  return {
    canStart: canStartSection136(actionInput),
    canEdit: canEditSection136(actionInput),
  };
}
