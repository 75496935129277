import z, { ZodType } from "zod";

export enum OrganisationStatus {
  Active = "active",
  Deleted = "deleted",
}

const OrganisationStatusEnum = z.nativeEnum(OrganisationStatus);

export enum OrganisationRoles {
  PoliceForce = "police/force",
  PoliceGeographicUnit = "police/geographic-unit",
  PoliceNationalUnit = "police/national-unit",
  PoliceCommandUnit = "police/command-unit",
  Admin = "admin",
}

export const OrganisationRolesEnum = z.nativeEnum(OrganisationRoles);
export type OrganisationRolesType = z.infer<typeof OrganisationRolesEnum>;

export type OrganisationIdUnbranded = string;
export const OrganisationIdSchema = z
  .string()
  .uuid()
  .brand(Symbol("OrganisationId")) satisfies ZodType<OrganisationIdUnbranded>;
export type OrganisationId = z.infer<typeof OrganisationIdSchema>;

export type OrganisationUnbranded = {
  id: OrganisationIdUnbranded;
  name: string;
  address: string;
  postalCode: string;
  managingOrganisationId: OrganisationIdUnbranded | null;
  role: OrganisationRoles;
  status: OrganisationStatus;
};

export const OrganisationSchema = z
  .object({
    id: OrganisationIdSchema,
    name: z.string().min(1).max(255),
    address: z.string().max(255),
    postalCode: z.string().max(20),
    managingOrganisationId: OrganisationIdSchema.nullable(),
    role: OrganisationRolesEnum,
    status: OrganisationStatusEnum,
  })
  .brand(Symbol("Organisation")) satisfies ZodType<OrganisationUnbranded>;

export type Organisation = z.infer<typeof OrganisationSchema>;

export type AvailableOrganisationUnbranded = {
  id: OrganisationIdUnbranded;
  name: string;
  managingOrganisationId: OrganisationIdUnbranded | null;
  role: OrganisationRoles;
};

export const AvailableOrganisationSchema = z
  .object({
    id: OrganisationIdSchema,
    name: z.string().min(1).max(255),
    managingOrganisationId: OrganisationIdSchema.nullable(),
    role: OrganisationRolesEnum,
  })
  .brand(
    Symbol("AvailableOrganisation"),
  ) satisfies ZodType<AvailableOrganisationUnbranded>;

export type AvailableOrganisation = z.infer<typeof AvailableOrganisationSchema>;

export const AvailableOrganisationArraySchema = z.array(
  AvailableOrganisationSchema,
);
export type AvailableOrganisationArray = z.infer<
  typeof AvailableOrganisationArraySchema
>;

export enum UserOrganisationRole {
  Officer = "officer",
  Supervisor = "supervisor",
  ClientAdmin = "client-admin",
  AnalyticsViewer = "analytics-viewer",
  GuestUser = "guest-user",
}

export type UserOrganisationMembershipUnbranded = {
  organisationId: OrganisationIdUnbranded;
  role: UserOrganisationRole;
  organisationName: string;
  organisationRole: OrganisationRoles;
  managingOrganisationId: OrganisationIdUnbranded | null;
  type: string;
};

export const UserOrganisationMembershipSchema = z
  .object({
    organisationId: OrganisationIdSchema,
    role: z.nativeEnum(UserOrganisationRole),
    organisationName: z.string(),
    organisationRole: OrganisationRolesEnum,
    managingOrganisationId: OrganisationIdSchema.nullable(),
    type: z.string(),
  })
  .brand(
    Symbol("UserOrganisationMembership"),
  ) satisfies ZodType<UserOrganisationMembershipUnbranded>;

export type UserOrganisationMembership = z.infer<
  typeof UserOrganisationMembershipSchema
>;
