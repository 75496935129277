import { z } from "zod";
import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/MultiPageFormBuilder.js";
import {
  dateSchema,
  ethnicitySchema,
  forenameSchema,
  freeTextSchema,
  genderSchema,
  pncWarningMarkersSchema,
  selfDeclaredEthnicitySchema,
  surnameSchema,
  valueAndLabelSchema,
  yesNoSchema,
  yesNoUnknownSchema,
} from "../schemas.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysRequired({
    fieldName: "patientForename",
    type: "textbox",
    schema: forenameSchema,
    label: "createIncident.personForename",
  })
  .withFieldAlwaysRequired({
    fieldName: "patientSurname",
    type: "textbox",
    schema: surnameSchema,
    label: "createIncident.personSurname",
  })
  .withFieldAlwaysRequired({
    fieldName: "patientAddress",
    type: "textbox",
    schema: freeTextSchema,
    label: "personDetails.homeAddress",
  })
  .withFieldAlwaysRequired({
    fieldName: "patientLocalAuthoirityAddress",
    type: "textbox",
    schema: freeTextSchema,
    label: "personDetails.localAuthorityAddress",
  })
  .withFieldAlwaysRequired({
    fieldName: "dateOfBirth",
    type: "date",
    schema: dateSchema,
    label: "personDetails.dob",
  })
  .withFieldAlwaysRequired({
    fieldName: "gender",
    type: "dropdown",
    schema: valueAndLabelSchema(genderSchema),
    label: "personDetails.gender.title",
    options: [
      {
        label: "personDetails.gender.options.male",
        value: "male",
      },
      {
        label: "personDetails.gender.options.female",
        value: "female",
      },
      {
        label: "personDetails.gender.options.nonBinary",
        value: "nonBinary",
      },
      {
        label: "personDetails.gender.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "ethnicAppearance",
    type: "dropdown",
    schema: valueAndLabelSchema(ethnicitySchema),
    label: "personDetails.ethnicity.title",
    options: [
      {
        label: "personDetails.ethnicity.options.IC1",
        value: "IC1",
      },
      {
        label: "personDetails.ethnicity.options.IC3",
        value: "IC3",
      },
      {
        label: "personDetails.ethnicity.options.IC4",
        value: "IC4",
      },
      {
        label: "personDetails.ethnicity.options.IC5",
        value: "IC5",
      },
      {
        label: "personDetails.ethnicity.options.IC6",
        value: "IC6",
      },
      {
        label: "personDetails.ethnicity.options.IC7",
        value: "IC7",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "selfDeclaredEthnicity",
    type: "dropdown",
    schema: valueAndLabelSchema(selfDeclaredEthnicitySchema),
    label: "personDetails.selfDeclaredEthnicity.title",
    options: [
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteEnglish",
        value: "whiteEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteIrish",
        value: "whiteIrish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteGypsy",
        value: "whiteGypsy",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteRoma",
        value: "whiteRoma",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.whiteOther",
        value: "whiteOther",
      },
      {
        label:
          "personDetails.selfDeclaredEthnicity.options.mixedBlackCaribbean",
        value: "mixedBlackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedBlackAfrican",
        value: "mixedBlackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedAsian",
        value: "mixedAsian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.mixedOther",
        value: "mixedOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianEnglish",
        value: "asianEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianIndian",
        value: "asianIndian",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianPakistani",
        value: "asianPakistani",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianBangladeshi",
        value: "asianBangladeshi",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianChinese",
        value: "asianChinese",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.asianOther",
        value: "asianOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackEnglish",
        value: "blackEnglish",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackAfrican",
        value: "blackAfrican",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackCaribbean",
        value: "blackCaribbean",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.blackOther",
        value: "blackOther",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.arab",
        value: "arab",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.other",
        value: "other",
      },
      {
        label: "personDetails.selfDeclaredEthnicity.options.preferNotToSay",
        value: "preferNotToSay",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "completedPoliceNationalComputerCheck",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "personDetails.nationalComputerCheck",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "pncRecordForPerson",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    isRequired: ({ formValues }) =>
      formValues.completedPoliceNationalComputerCheck?.value === "yes",
    label: "personDetails.pncRecordForPerson",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "pncidNumber",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.pncRecordForPerson?.value === "yes",
    label: "personDetails.pncidNumber",
  })
  .withFieldConditionallyRequired({
    fieldName: "pncWarningMarkers",
    type: "dropdown",
    schema: valueAndLabelSchema(pncWarningMarkersSchema),
    isRequired: ({ formValues }) =>
      formValues.pncRecordForPerson?.value === "yes",
    label: "personDetails.pncWarningMarkers.title",
    options: [
      {
        label: "personDetails.pncWarningMarkers.options.alleges",
        value: "alleges",
      },
      {
        label: "personDetails.pncWarningMarkers.options.ailment",
        value: "ailment",
      },
      {
        label: "personDetails.pncWarningMarkers.options.conceals",
        value: "conceals",
      },
      {
        label: "personDetails.pncWarningMarkers.options.contagious",
        value: "contagious",
      },
      {
        label: "personDetails.pncWarningMarkers.options.drugs",
        value: "drugs",
      },
      {
        label: "personDetails.pncWarningMarkers.options.escaper",
        value: "escaper",
      },
      {
        label: "personDetails.pncWarningMarkers.options.firearm",
        value: "firearm",
      },
      {
        label: "personDetails.pncWarningMarkers.options.fImpers",
        value: "f/Impers",
      },
      {
        label: "personDetails.pncWarningMarkers.options.mImpers",
        value: "m/Impers",
      },
      {
        label: "personDetails.pncWarningMarkers.options.impersonates",
        value: "impersonates",
      },
      {
        label: "personDetails.pncWarningMarkers.options.mentalHealth",
        value: "mentalHealth",
      },
      {
        label: "personDetails.pncWarningMarkers.options.selfHarm",
        value: "selfHarm",
      },
      {
        label: "personDetails.pncWarningMarkers.options.suicidal",
        value: "suicidal",
      },
      {
        label: "personDetails.pncWarningMarkers.options.violent",
        value: "violent",
      },
      {
        label: "personDetails.pncWarningMarkers.options.weapons",
        value: "weapons",
      },
      {
        label: "personDetails.pncWarningMarkers.options.explosives",
        value: "explosives",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "hasRelativeOrFriend",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "personDetails.relativeOrFriend",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendForename",
    type: "textbox",
    schema: forenameSchema,
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendForename",
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendSurname",
    type: "textbox",
    schema: surnameSchema,
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSurname",
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendRelationship",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendRelationship",
  })
  .withFieldConditionallyRequired({
    fieldName: "unknownRelativeOrFriend",
    type: "info-banner",
    schema: z.any().optional(), // TODO optional is currently not working - will be fixed in ASP-2871
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "no",
    label: "personDetails.unknownRelativeOrFriend",
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendSameAddress",
    type: "radio",
    schema: valueAndLabelSchema(yesNoUnknownSchema),
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendSameAddress",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
      {
        label: "unknown",
        value: "unknown",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendAddress",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.relativeOrFriendSameAddress?.value === "yes" ||
      formValues.relativeOrFriendSameAddress?.value === "no",
    label: "personDetails.relativeOrFriendAddress",
  })
  .withFieldConditionallyRequired({
    fieldName: "unknownRelativeOrFriendAddress",
    type: "info-banner",
    schema: z.any().optional(), // TODO optional is currently not working - will be fixed in ASP-2871
    isRequired: ({ formValues }) =>
      formValues.relativeOrFriendSameAddress?.value === "unknown",
    label: "personDetails.unknownRelativeOrFriendAddress",
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendTelephoneNumber",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendTelephone",
  })
  .withFieldConditionallyRequired({
    fieldName: "relativeOrFriendInformed",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    isRequired: ({ formValues }) =>
      formValues.hasRelativeOrFriend?.value === "yes",
    label: "personDetails.relativeOrFriendInformed",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldConditionallyRequired({
    fieldName: "reasonNotInformed",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.relativeOrFriendInformed?.value === "no",
    label: "personDetails.reasonNotInformed",
  })
  .withPage(["patientForename", "patientSurname"])
  .withPage(["patientAddress"])
  .withPage(["patientLocalAuthoirityAddress"])
  .withPage(["dateOfBirth"])
  .withPage(["gender"])
  .withPage(["ethnicAppearance", "selfDeclaredEthnicity"])
  .withPage(["completedPoliceNationalComputerCheck"])
  .withPage(["pncRecordForPerson"])
  .withPage(["pncidNumber", "pncWarningMarkers"])
  .withPage([
    "hasRelativeOrFriend",
    "relativeOrFriendForename",
    "relativeOrFriendSurname",
    "relativeOrFriendRelationship",
    "unknownRelativeOrFriend",
  ])
  .withPage([
    "relativeOrFriendSameAddress",
    "relativeOrFriendAddress",
    "unknownRelativeOrFriendAddress",
  ])
  .withPage([
    "relativeOrFriendTelephoneNumber",
    "relativeOrFriendInformed",
    "reasonNotInformed",
  ])
  .build();

export type PersonDetailsFormData = typeof formSpecification.formType;
