import { z } from "zod";
import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/MultiPageFormBuilder.js";
import {
  dateTimeSchema,
  freeTextSchema,
  valueAndLabelSchema,
  yesNoSchema,
} from "../schemas.js";

export const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysRequired({
    fieldName: "custodyNumber",
    type: "textbox",
    schema: z.any().optional(), // TODO optional is currently not working - will be fixed in ASP-2871
    label: "detentionDetails.custodyNumber",
  })
  .withFieldAlwaysRequired({
    fieldName: "dateTimeOfDetention",
    type: "date-time",
    timeLabel: "detentionDetails.timeOfDetention",
    dateLabel: "detentionDetails.dateOfDetention",
    schema: dateTimeSchema,
  })
  .withFieldAlwaysRequired({
    fieldName: "responsibleForDetention",
    type: "textbox",
    schema: freeTextSchema,
    label: "detentionDetails.responsibleForDetention",
  })
  .withFieldAlwaysRequired({
    fieldName: "detentionTookPlace",
    type: "textbox",
    schema: freeTextSchema,
    label: "detentionDetails.detentionTookPlace",
  })
  .withFieldAlwaysRequired({
    fieldName: "locationType",
    type: "dropdown",
    schema: valueAndLabelSchema(freeTextSchema),
    label: "detentionDetails.locationType.title",
    options: [
      {
        value: "railwayLines",
        label: "detentionDetails.locationType.railwayLines",
      },
      {
        value: "hospitalWards",
        label: "detentionDetails.locationType.hospitalWards",
      },
      {
        value: "rooftopsCommercial",
        label: "detentionDetails.locationType.rooftopsCommercial",
      },
      {
        value: "policeStations",
        label: "detentionDetails.locationType.policeStations",
      },
      { value: "offices", label: "detentionDetails.locationType.offices" },
      { value: "school", label: "detentionDetails.locationType.school" },
      {
        value: "gardensCarParks",
        label: "detentionDetails.locationType.gardensCarParks",
      },
      {
        value: "nonResidentialRestricted",
        label: "detentionDetails.locationType.nonResidentialRestricted",
      },
      { value: "other", label: "detentionDetails.locationType.other" },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "wasLifesavingIntervention",
    type: "radio",
    schema: valueAndLabelSchema(yesNoSchema),
    label: "detentionDetails.wasLifesavingIntervention",
    options: [
      {
        label: "common.yes",
        value: "yes",
      },
      {
        label: "common.no",
        value: "no",
      },
    ],
  })
  .withFieldAlwaysRequired({
    fieldName: "wasLifesavingInterventionDetails",
    type: "info-banner",
    schema: z.any().optional(), // TODO optional is currently not working - will be fixed in ASP-2871
    label: "detentionDetails.wasLifesavingInterventionDetails",
  })
  .withFieldConditionallyRequired({
    fieldName: "intervention",
    type: "textbox",
    schema: freeTextSchema,
    isRequired: ({ formValues }) =>
      formValues.wasLifesavingIntervention?.value === "yes",
    label: "detentionDetails.intervention",
  })
  .withPage(["custodyNumber"])
  .withPage(["dateTimeOfDetention"])
  .withPage(["responsibleForDetention"])
  .withPage(["detentionTookPlace"])
  .withPage(["locationType"])
  .withPage(["wasLifesavingIntervention", "wasLifesavingInterventionDetails"])
  .withPage(["intervention"])
  .build();

export type DetentionDetailsFormData = typeof formSpecification.formType;
