import React from "react";

import { Box, Typography } from "@mui/material";

export const FormError = ({ error, ...props }: { error: string }) => {
  return (
    <Box sx={{ ml: 1.5, pt: 0.5 }}>
      <Typography
        color="error"
        fontWeight={"400"}
        fontSize={"0.75rem"}
        {...props}
      >
        {error}
      </Typography>
    </Box>
  );
};
