import React from "react";

import { Box, TextField as MuiTextField } from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import { Tooltip } from "../../Tooltip/Tooltip.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

export type TextFieldProps = {
  fieldName: string;
  label?: TranslationKey;
  tooltip?: {
    title: TranslationKey;
    content: TranslationKey;
  };
};

export function TextField({ fieldName, label, tooltip }: TextFieldProps) {
  const [fieldInput, fieldMeta] = useField<string>(fieldName);

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="flex-start"
        marginBottom="0.5rem"
      >
        {label && <FormLabel label={label} />}
        {tooltip && (
          <Tooltip
            fieldName={fieldName}
            title={tooltip.title}
            content={tooltip.content}
          />
        )}
      </Box>
      <MuiTextField
        id={fieldName}
        name={fieldName}
        value={fieldInput.value}
        error={fieldMeta.touched && Boolean(fieldMeta.error)}
        helperText={fieldMeta.touched && fieldMeta.error}
        inputProps={{
          "data-testid": `formField-${fieldName}-input`, // Add the test ID to the input element
        }}
        onChange={fieldInput.onChange}
        onBlur={fieldInput.onBlur}
        fullWidth
      />
    </Box>
  );
}
