import type { ActionInput } from "../ActionInput.js";
import {
  hasArrivedAtActivePlaceOfSafety,
  hasPlaceOfSafety,
  hasTriageForm,
  incidentIsActive,
  userIsIncidentOwner,
} from "../helpers.js";

type MarkArrivalAction = {
  canMarkArrival: boolean;
};

function canMarkArrival(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput) &&
    hasPlaceOfSafety(actionInput) &&
    !hasArrivedAtActivePlaceOfSafety(actionInput)
  );
}

export function calculateArrivalActions(
  actionInput: ActionInput,
): MarkArrivalAction {
  return {
    canMarkArrival: canMarkArrival(actionInput),
  };
}
