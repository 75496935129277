import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TranslationKey } from "@vision/common";
import { useField } from "formik";
import { isEqual } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormError } from "../FormError/FormError.js";
import { FormLabel } from "../FormLabel/FormLabel.js";

export function RadioButtonGroup({
  fieldName,
  label,
  options,
  ...props
}: {
  fieldName: string;
  label?: TranslationKey;
  options: { label: TranslationKey; value: string }[];
}) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(fieldName);
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    const findInitialValue = () => {
      for (const option of options) {
        const optionWithTranslatedLabel = {
          value: option.value,
          label: t(option.label),
        };
        if (isEqual(optionWithTranslatedLabel, field.value)) {
          return optionWithTranslatedLabel;
        }
      }
      return null;
    };
    const initialValue = findInitialValue();
    setSelectedValue(initialValue);
  }, [field.value, options, t]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(JSON.parse(event.target.value));
  };

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <FormControl component="fieldset">
        {label && <FormLabel data-testid="radio-label" label={label} />}
        <RadioGroup
          data-testid="radio-group"
          value={JSON.stringify(selectedValue)}
          onChange={handleChange}
          {...props}
          name={fieldName}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.value}
                value={JSON.stringify({
                  value: option.value,
                  label: t(option.label),
                })}
                control={<Radio />}
                label={t(option.label)}
                data-testid={`radio-option-${option.value}`}
              />
            );
          })}
        </RadioGroup>
        {meta.touched && meta.error && (
          <FormError data-testid="radio-error" error={meta.error} />
        )}
      </FormControl>
    </Box>
  );
}
