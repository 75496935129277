import type { ActionInput } from "../ActionInput.js";
import {
  hasPlaceOfSafety,
  hasTriageForm,
  incidentIsActive,
  userIsIncidentOwner,
} from "../helpers.js";

type AddPlaceOfSafetyAction = {
  canAdd: boolean;
};

type EditPlaceOfSafetyAction = {
  canEdit: boolean;
};

type PlaceOfSafetyActions = AddPlaceOfSafetyAction & EditPlaceOfSafetyAction;

function canAddPlaceOfSafety(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput)
  );
}

function canEditPlaceOfSafety(actionInput: ActionInput): boolean {
  return (
    incidentIsActive(actionInput) &&
    userIsIncidentOwner(actionInput) &&
    hasTriageForm(actionInput) &&
    hasPlaceOfSafety(actionInput)
  );
}

export function calculatePlaceOfSafetyActions(
  actionInput: ActionInput,
): PlaceOfSafetyActions {
  return {
    canAdd: canAddPlaceOfSafety(actionInput),
    canEdit: canEditPlaceOfSafety(actionInput),
  };
}
