import { z } from "zod";

export enum WorkflowVersions {
  ThalamosMin01 = "thalamos-min-0.1",
  ThalamosMax01 = "thalamos-max-0.1",
}

export const WorkflowVersionsEnum = z.nativeEnum(WorkflowVersions);

export const DEFAULT_WORKFLOW_VERSION = WorkflowVersions.ThalamosMin01;
