import React from "react";

import { Box, Typography } from "@mui/material";
import { FormBuilderField } from "@vision/common";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import Banner, { BannerList } from "../Banner/Banner.js";
import { Checkbox } from "./Checkbox/Checkbox.js";
import { DatePicker } from "./DatePicker/DatePicker.js";
import { DateTimePicker } from "./DateTimePicker/DateTimePicker.js";
import { Dropdown } from "./Dropdown/Dropdown.js";
import { CheckboxGroup } from "./MultiSelect/MultiSelect.js";
import { OrganisationDropdown } from "./OrganisationDropdown/OrganisationDropdown.js";
import { PlaceOfSafetyPicker } from "./PlaceOfSafetyPicker/PlaceOfSafetyPicker.js";
import { RadioButtonGroup } from "./RadioButton/RadioButton.js";
import { TextField } from "./TextField/TextField.js";

export type FieldProps<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
> = {
  field: FormBuilderField<Form, Field>;
} & FormikProps<Form>;

export function Field<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
>({ field, values }: FieldProps<Form, Field>) {
  const { t } = useTranslation();

  // Every time the value changes, consider changing the values of dependent fields
  switch (field.type) {
    case "date":
      return <DatePicker fieldName={field.fieldName} label={field.label} />;

    case "date-time":
      return (
        <DateTimePicker
          fieldName={field.fieldName}
          dateLabel={field.dateLabel}
          timeLabel={field.timeLabel}
        />
      );
    case "textbox":
      return (
        <TextField
          fieldName={field.fieldName}
          label={field.label}
          tooltip={field.tooltip}
        />
      );
    case "checkbox":
      return <Checkbox fieldName={field.fieldName} label={field.label} />;
    case "readonly":
      return (
        <Box>
          <Typography sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
            {t(field.label)}
          </Typography>
          <Typography>{field.textFn(values)}</Typography>
        </Box>
      );
    case "organisation":
      return (
        <OrganisationDropdown
          fieldName={field.fieldName}
          label={field.label}
          values={values}
          options={field.options}
          filterByRoles={field.filterByRoles}
        />
      );
    case "placeOfSafety":
      return (
        <PlaceOfSafetyPicker fieldName={field.fieldName} label={field.label} />
      );
    case "dropdown":
      return (
        <Box>
          <Dropdown
            fieldName={field.fieldName}
            label={field.label}
            options={field.options}
          />
        </Box>
      );
    case "radio":
      return (
        <RadioButtonGroup
          fieldName={field.fieldName}
          label={field.label}
          options={field.options}
        />
      );
    case "info-banner":
      return (
        <Box>
          <Banner body={t(field.label)} bannerType={BannerList.INFO} />
        </Box>
      );

    case "multi-select":
      return (
        <CheckboxGroup
          fieldName={field.fieldName}
          label={field.label}
          options={field.options}
        />
      );
  }
  return null;
}
