import { MultiPageFormBuilder } from "../../support/MultiPageFormBuilder/MultiPageFormBuilder.js";
import { OrganisationRoles } from "../../types/organisation.js";
import {
  commandUnitSchema,
  dispatchNumberSchema,
  forenameSchema,
  surnameSchema,
} from "../schemas.js";
import {
  FormSection,
  type FormDefinition,
  type FormSectionDefinition,
} from "../Workflow.js";

const formSpecification = new MultiPageFormBuilder()
  .withFieldAlwaysRequired({
    fieldName: "dispatchNumber",
    type: "textbox",
    schema: dispatchNumberSchema,
    label: "common.dispatchNumber",
  })
  .withFieldAlwaysRequired({
    fieldName: "patientForename",
    type: "textbox",
    schema: forenameSchema,
    label: "createIncident.personForename",
  })
  .withFieldAlwaysRequired({
    fieldName: "patientSurname",
    type: "textbox",
    schema: surnameSchema,
    label: "createIncident.personSurname",
  })
  .withFieldAlwaysRequired({
    fieldName: "commandUnit",
    type: "organisation",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: (data: any) => data.availableOrganisations,
    filterByRoles: [OrganisationRoles.PoliceGeographicUnit],
    schema: commandUnitSchema,
    label: "createIncident.commandUnit",
  })
  .withPage(["dispatchNumber"])
  .withPage(["patientForename", "patientSurname"])
  .withPage(["commandUnit"])
  .build();

export type CreateIncidentWorkItemFormData = typeof formSpecification.formType;

const createIncidentThalamosMinV01: FormDefinition<
  [
    FormSectionDefinition<
      FormSection.createIncidentMain,
      CreateIncidentWorkItemFormData
    >,
  ]
> = {
  sections: [
    {
      id: FormSection.createIncidentMain,
      autosave: false,
      strictFieldSchemas: formSpecification.strictFieldSchemas,
      formSchema: formSpecification.formSchema,
      name: "incidentWorkItem.startNew",
      pages: formSpecification.pages,
    },
  ],
};

export default createIncidentThalamosMinV01;
