import React, { useMemo } from "react";

import {
  createInitialValues,
  FormSection,
  workflows,
  WorkflowVersions,
} from "@vision/common";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";
import { Error } from "../../pages/Error/Error.js";

type PersonDetailsSchema<T extends WorkflowVersions> = z.infer<
  (typeof workflows)[T]["136"]["formDefinition"]["sections"][0]["formSchema"]
>;

///

export function PersonDetailsFormSkeleton() {
  return <MultiPageFormSkeleton headerText={"personDetails.title"} />;
}

///

export type PersonDetailsFormProps<T extends WorkflowVersions> = {
  onSubmit: (value: PersonDetailsSchema<T>) => void;
  onCancel: () => void;
  workflowVersion: T;
  initialValues?: PersonDetailsSchema<T>;
};

///

export function PersonDetailsForm<T extends WorkflowVersions>(
  props: PersonDetailsFormProps<T>,
) {
  const workflow = workflows[props.workflowVersion];
  const section = workflow[136].formDefinition.sections.find(
    (section) => section.id === FormSection.personDetails,
  );

  const formSchema = section?.formSchema;
  const strictFieldSchemas = section?.strictFieldSchemas;

  const initialValuesOrDefaults = useMemo(() => {
    if (!section || !strictFieldSchemas || !formSchema) {
      return {};
    }
    return (
      props.initialValues ??
      (createInitialValues(strictFieldSchemas) as z.infer<typeof formSchema>)
    );
  }, [props, strictFieldSchemas, section, formSchema]);

  if (!section || !formSchema || !strictFieldSchemas) {
    return <Error />;
  }

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"personDetails.title"}
      schema={formSchema}
      strictFieldSchemas={strictFieldSchemas}
      initialValues={initialValuesOrDefaults}
      pages={section.pages}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
