import { PlaceOfSafety } from "@thalamos/common";
import {
  AvailableOrganisationArray,
  AvailableOrganisationArraySchema,
  ExtendedVisionUser,
  ExtendedVisionUserSchema,
  GetPlacesOfSafetyResponseSchema,
  OrganisationId,
} from "@vision/common";
import { redirect } from "react-router-dom";
import api from "../../api/index.js";
import i18n from "../../i18n.js";
import { setUser } from "../../tracing.js";
import { Deferred } from "./util.js";

export type RootLoaderData = {
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
  placesOfSafety: PlaceOfSafety[];
};

export type RootLoaderDeferredData = Deferred<RootLoaderData>;

const getAvailableOrganisations = async (
  orgId: OrganisationId,
): Promise<AvailableOrganisationArray> => {
  const response = await api.organisation.getAvailableOrganisations(orgId);

  if (response.status === 200) {
    const availableOrganisations =
      await AvailableOrganisationArraySchema.parseAsync(response.data);
    const sortedOrganisations = availableOrganisations.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortedOrganisations;
  }

  throw new Error(`Failed to fetch child organisations: ${response.status}`);
};

const getPlacesOfSafety = async (): Promise<PlaceOfSafety[]> => {
  return api.placeOfSafety.getAll().then(async (response) => {
    if (response.status === 200) {
      return GetPlacesOfSafetyResponseSchema.parseAsync(response.data).then(
        (data) => data.placesOfSafety,
      );
    } else {
      throw Error(`Failed to fetch places of safety: ${response.status}`);
    }
  });
};

export const rootLoader = async (): Promise<RootLoaderData | Response> => {
  try {
    const response = await api.user.me();
    if (response.status === 200) {
      const user = await ExtendedVisionUserSchema.parseAsync(response.data);

      if (user.sessionOrganisationConfiguration?.language) {
        i18n.changeLanguage(user.sessionOrganisationConfiguration.language);
      }

      const orgId = user.sessionContext?.organisationId;
      if (!orgId) {
        throw new Error("User has no organisation");
      }

      const availableOrganisations = await getAvailableOrganisations(orgId);
      const placesOfSafety = await getPlacesOfSafety();

      // Set datadog tracing user
      setUser(user);

      return { user, availableOrganisations, placesOfSafety };
    }
  } catch (_error) {
    // ignore error
  }
  throw redirect("/login");
};
